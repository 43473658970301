import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginGloboCallback } from "../../../services/auth/use-login-globo-callback";
import { Spinner } from "../../../components";
import {
  Container,
  Description,
  Logo,
  Message,
  WrapperMessage,
} from "./redirect.styles";

function ErrorMessage({ error }: { error: any }) {
  if (!error) return <></>;

  if (error?.response?.data?.errors) {
    const [errorItem] = error?.response?.data?.errors;
    return (
      <WrapperMessage>
        <Message>{errorItem?.message}</Message>
        <Description>{errorItem?.description}</Description>
      </WrapperMessage>
    );
  }

  return (
    <Message>
      Houve um problema durante seu login. Por favor, tente novamente
    </Message>
  );
}

export function Redirect() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<any>();
  const code = searchParams.get("code");
  const session_state = searchParams.get("session_state");

  const navigate = useNavigate();
  const { mutateAsync: loginGloboCallback } = useLoginGloboCallback();

  useEffect(() => {
    async function makeCallback() {
      try {
        await loginGloboCallback({ code, session_state });
        navigate("/");
      } catch (err) {
        setError(err);
      }
    }
    makeCallback();
  }, [code, loginGloboCallback, navigate, session_state]);

  return (
    <Container>
      <Logo />
      {error ? <ErrorMessage error={error} /> : <Spinner />}
    </Container>
  );
}
