import { useRef } from "react";
import {
  Button,
  DefaultLayout,
  Alert,
  AlertHandle,
  Row,
  IconButton,
  Can,
  List,
} from "../../components";
import { CreateParticipantFormModal } from "./modal/create-participant-form-modal/create-participant-form-modal";
import { CreateParticipantFormModalHandle } from "./modal/create-participant-form-modal/create-participant-form-modal.types";
import { ParticipantFormSubmitData } from "./components/participant-form/participant-form.types";
import { useListParticipants } from "../../services/participants/use-list-participants";
import { useCreateParticipant } from "../../services/participants/use-create-participant";
import { Participant } from "../../types/participant";
import { UpdateParticipantFormModal } from "./modal/update-participant-form-modal/update-participant-form-modal";
import {
  OnUpdateParticipantFormData,
  UpdateParticipantFormModalHandle,
} from "./modal/update-participant-form-modal/update-participant-form-modal.types";
import { useUpdateParticipant } from "../../services/participants/use-update-participant";
import { useDeleteParticipant } from "../../services/participants/use-delete-participant";
import { useToast } from "../../hooks/use-toast";

export function ParticipantsPage() {
  const alertRef = useRef<AlertHandle>(null);
  const { errorHandler } = useToast();
  const { data: participants, isLoading } = useListParticipants();
  const { mutateAsync: createParticipant } = useCreateParticipant();
  const { mutateAsync: updateParticipant } = useUpdateParticipant();
  const { mutateAsync: deleteParticipant } = useDeleteParticipant();
  const createParticipantFormModalRef =
    useRef<CreateParticipantFormModalHandle>(null);
  const updateParticipantFormModalRef =
    useRef<UpdateParticipantFormModalHandle>(null);

  function onClickCreateParticipant() {
    createParticipantFormModalRef?.current?.show();
  }

  async function onClickDeleteParticipant(participant: Participant) {
    alertRef?.current?.show({
      title: "Deseja mesmo excluir?",
      description: `Deseja mesmo excluir o participante "${participant.name}"?`,
      buttons: [
        {
          children: "Sim, excluir",
          onClick: () => onDeleteParticipant(participant),
        },
        { children: "Não", variant: "ghost" },
      ],
    });
  }

  function onClickUpdateParticipant(participant: Participant) {
    updateParticipantFormModalRef?.current?.show(participant);
  }

  async function onDeleteParticipant(participant: Participant) {
    try {
      await deleteParticipant({ participantId: participant.id });
    } catch (error) {
      errorHandler(error);
    }
  }

  async function onCreateParticipant(data: ParticipantFormSubmitData) {
    await createParticipant(data);
  }

  async function onUpdateParticipant(data: OnUpdateParticipantFormData) {
    await updateParticipant(data);
  }

  return (
    <DefaultLayout
      isLoading={isLoading}
      headlineRightComponent={
        <Can permissions={["participant.create"]}>
          <Button
            leftIconName="add"
            onClick={onClickCreateParticipant}
            variant="outline"
          >
            Adicionar participante
          </Button>
        </Can>
      }
      headline={{ title: "Participantes" }}
    >
      {participants?.length && (
        <List
          data={participants?.map((participant) => ({
            title: participant.name,
            avatar: { name: participant?.profile?.name },
            badge: {
              text: participant.statusText,
              bgColor: participant.statusColor,
            },
            rightContent: (
              <Row>
                <Can permissions={["participant.update"]}>
                  <IconButton
                    iconName="edit"
                    onClick={() => onClickUpdateParticipant(participant)}
                  />
                </Can>
                <Can permissions={["participant.delete"]}>
                  <IconButton
                    iconName="delete"
                    onClick={() => onClickDeleteParticipant(participant)}
                  />
                </Can>
              </Row>
            ),
          }))}
        />
      )}

      <Alert ref={alertRef} />
      <CreateParticipantFormModal
        ref={createParticipantFormModalRef}
        onRequestClose={() => createParticipantFormModalRef?.current?.hide()}
        onSubmit={onCreateParticipant}
      />
      <UpdateParticipantFormModal
        ref={updateParticipantFormModalRef}
        onRequestClose={() => updateParticipantFormModalRef?.current?.hide()}
        onSubmit={onUpdateParticipant}
      />
    </DefaultLayout>
  );
}
