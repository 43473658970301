import {
  Button,
  DefaultLayout,
  Icon,
  Label,
  List,
  Pagination,
} from "../../components";
import { useListLogs } from "../../services/logs/use-list-logs";
import { useQueryParams } from "../../hooks/use-query-params";
import { useEffect, useRef } from "react";
import { FiltersLogsModal } from "./modal/filters-logs-modal/filters-logs-modal";
import { FiltersLogsModalHandle } from "./modal/filters-logs-modal/filters-logs-modal.types";
import { FiltersLogsFormSubmitData } from "./components/filters-logs/filters-logs-form.types";

export function LogsPage() {
  const { add, get, addMany, getAll } = useQueryParams();
  const filtersLogsModalRef = useRef<FiltersLogsModalHandle>(null);
  const page = get("page");
  const allParams = getAll();

  useEffect(() => {
    if (page === null) {
      add("page", 0);
    }
  }, [page, add]);

  const { data: logs, isLoading } = useListLogs({
    params: { ...allParams, page: Number(page) },
  });

  function onChangePage(index: number) {
    add("page", index);
  }

  function openFilter() {
    filtersLogsModalRef.current?.show();
  }

  function closeFilter() {
    filtersLogsModalRef.current?.hide();
  }

  async function onSubmitFilter(filters: FiltersLogsFormSubmitData) {
    addMany({ ...filters, page: 0 });
  }

  return (
    <DefaultLayout
      isLoading={isLoading}
      headline={{ title: "Logs", description: "Lista de logs do sistema" }}
      headlineRightComponent={
        <Button leftIconName="filter" onClick={openFilter} variant="outline">
          Filtros
        </Button>
      }
    >
      {logs?.data?.length && (
        <>
          <List
            showSearch={false}
            data={logs?.data.map((log) => ({
              title: log.message,
              ...(log.category && { badge: { text: log.category } }),
              description: new Date(log.date).toLocaleString(),
              leftContent: (
                <Icon
                  name={log.typeIconName as any}
                  size={24}
                  color={log.typeColor}
                />
              ),
              rightContent: <Label>{log.user}</Label>,
            }))}
          />
          <Pagination
            onChange={onChangePage}
            pages={logs.pages}
            index={Number(page)}
          />
        </>
      )}
      <FiltersLogsModal
        onRequestClose={closeFilter}
        onSubmit={onSubmitFilter}
        defaultValues={allParams}
        ref={filtersLogsModalRef}
      />
    </DefaultLayout>
  );
}
