import styled from "styled-components";

export const WrapperIndicators = styled.nav`
  display: flex;
  gap: ${({ theme }) => theme.spaces[2]};
  padding: 0px 0px ${({ theme }) => theme.spaces[7]} 0px;
  align-items: center;
`;

export const WrapperIndicator = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  gap: ${({ theme }) => theme.spaces[3]};
  border: none;
  cursor: pointer;
`;

export const Separator = styled.div<{ $concluded: boolean }>`
  width: 50px;
  height: 2px;
  background-color: ${({ theme, $concluded }) =>
    $concluded ? theme.colors.primary : theme.colors.whiteA600};
`;

export const IndicatorBox = styled.div<{
  $concluded: boolean;
  $disabled: boolean;
}>`
  display: flex;
  height: ${({ theme }) => theme.sizes[8]};
  width: ${({ theme }) => theme.sizes[8]};
  background-color: ${({ $concluded, theme }) =>
    $concluded ? theme.colors.primary : "transparent"};
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.md};
  color: ${({ theme, $concluded }) =>
    $concluded ? theme.colors.blackA900 : theme.colors.whiteA700};
  border: 2px solid
    ${({ theme, $disabled }) =>
      $disabled ? theme.colors.whiteA300 : theme.colors.primary};
`;

export const IndicatorBoxContent = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const IndicatorText = styled.strong`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.colors.whiteA900};
  font-size: ${({ theme }) => theme.fontSizes.text_sm};
`;
