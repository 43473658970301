import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { AlertHandle, AlertProps, AlertShowProps } from "./alert.types";
import { Modal, ModalHandle } from "../../templates";
import { Button, ButtonProps } from "../../atoms";
import { Content, Description, Footer } from "./alert.styles";

function Component(_: AlertProps, ref: Ref<AlertHandle>): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buttons, setButtons] = useState<ButtonProps[]>([]);

  function show(props: AlertShowProps) {
    setTitle(props.title);
    setDescription(props.description);
    setButtons(props.buttons);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  function onClickButton(
    button: ButtonProps,
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (button.onClick) {
      button.onClick(evt);
    }
    hide();
  }

  return (
    <Modal size="sm" headline={{ title }} ref={modalRef}>
      <Content>
        <Description>{description}</Description>
        <Footer>
          {buttons?.map((button, index) => (
            <Button
              key={index}
              {...button}
              onClick={(evt) => onClickButton(button, evt)}
            />
          ))}
        </Footer>
      </Content>
    </Modal>
  );
}

export const Alert = forwardRef<AlertHandle, AlertProps>(Component);
