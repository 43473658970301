import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal, ModalHandle } from "../../../../../components";
import {
  MakeViewsInsertionsFormModalProps,
  MakeViewsInsertionsFormModalHandle,
  MakeViewsInsertionsFormModalShowData,
} from "./make-views-insertions-form-modal.types";
import { MusicsViewsForm } from "../../components/musics-views-form/musics-views-form";
import { MusicsViewsFormSubmitData } from "../../components/musics-views-form/musics-views-form.types";

function Component(
  props: MakeViewsInsertionsFormModalProps,
  ref: Ref<MakeViewsInsertionsFormModalHandle>
): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [showData, setShowData] =
    useState<MakeViewsInsertionsFormModalShowData>();

  function show(data: MakeViewsInsertionsFormModalShowData) {
    setShowData(data);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  async function onSubmit(data: MusicsViewsFormSubmitData) {
    await props.onSubmit({ musics: data, platformId: showData?.platformId! });
    props.onRequestClose();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <Modal
      size="lg"
      headline={{ title: showData?.platformName || "" }}
      ref={modalRef}
    >
      <MusicsViewsForm {...props} onSubmit={onSubmit} />
    </Modal>
  );
}

export const MakeViewsInsertionFormModal = forwardRef<
  MakeViewsInsertionsFormModalHandle,
  MakeViewsInsertionsFormModalProps
>(Component);
