import styled from "styled-components";
import { Icon } from "../../atoms";

export const Container = styled.article`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces[6]};
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.radii.md};
  padding: ${({ theme }) => theme.spaces[4]} ${({ theme }) => theme.spaces[6]};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
  flex: 1;
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.colors.onSurfacePrimary};
  font-size: ${({ theme }) => theme.fontSizes.text_sm};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes.text_lg};
  }
`;

export const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.text_md};
  color: ${({ theme }) => theme.colors.onSurfaceSecondary};
  margin-bottom: ${({ theme }) => theme.spaces[2]};
`;

export const WrapperActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[2]};
`;

export const Chevron = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.onSurfaceSecondary,
  size: 18,
}))``;
