import { useSearchParams } from "react-router-dom";
import {
  Alert,
  AlertHandle,
  Button,
  Can,
  DefaultLayout,
  Row,
  List,
  Label,
} from "../../../components";
import { useGetCalculationPeriod } from "../../../services/calculation-periods/use-get-calculation-period";
import { useCloseCalculationPeriod } from "../../../services/calculation-periods/use-close-calculation-period";
import { useToast } from "../../../hooks/use-toast";
import { useViewsInsertions } from "../../../services/calculation-periods/use-views-insertions";

import { MakeViewsInsertionFormModal } from "./modal/make-views-insertions-form-modal/make-views-insertions-form-modal";
import { useRef } from "react";
import {
  MakeViewsInsertionsFormModalHandle,
  MakeViewsInsertionsFormModalSubmitData,
} from "./modal/make-views-insertions-form-modal/make-views-insertions-form-modal.types";
import { Platform } from "../../../types/platform";
import { useActiveCalculationPeriod } from "../../../services/calculation-periods/use-active-calculation-period";
import styled from "styled-components";

export function CalculationPeriodDetailPage() {
  const [searchParams] = useSearchParams();
  const alertRef = useRef<AlertHandle>(null);
  const { errorHandler, showMessage } = useToast();
  const { mutateAsync: closeCalculationPeriod } = useCloseCalculationPeriod();
  const { mutateAsync: activeCalculationPeriod } = useActiveCalculationPeriod();
  const { mutateAsync: makeViewsInsertions } = useViewsInsertions();
  const makeViewsInsertionFormModalRef =
    useRef<MakeViewsInsertionsFormModalHandle>(null);
  const id = searchParams.get("id");

  const { data: calculationPeriod, isLoading } = useGetCalculationPeriod({
    params: { id: id! },
    config: { enabled: !!id },
  });

  async function onSubmit(values: MakeViewsInsertionsFormModalSubmitData) {
    await makeViewsInsertions({
      ...values,
      calculationPeriodId: calculationPeriod?.id!,
    });
    showMessage("Views inseridas com sucesso!");
  }

  function onClickInsert(platform: Platform) {
    makeViewsInsertionFormModalRef?.current?.show({
      platformId: platform.id,
      platformName: platform.name,
    });
  }

  async function onClickCloseCalculationPeriod() {
    alertRef?.current?.show({
      title: "Deseja mesmo encerrar?",
      description: `Deseja mesmo encerrar a apuração "${calculationPeriod?.name}"?`,
      buttons: [
        {
          children: "Sim, encerrar",
          onClick: onCloseCalculationPeriod,
        },
        { children: "Não", variant: "ghost" },
      ],
    });
  }

  async function onCloseCalculationPeriod() {
    try {
      await closeCalculationPeriod({
        calculationPeriodId: calculationPeriod?.id!,
      });
    } catch (error) {
      errorHandler(error);
    }
  }

  async function onClickActiveCalculationPeriod() {
    try {
      await activeCalculationPeriod({
        calculationPeriodId: calculationPeriod?.id!,
      });
    } catch (error) {
      errorHandler(error);
    }
  }

  return (
    <DefaultLayout
      isLoading={isLoading}
      goBackHref="/calculation-periods"
      headline={{
        title: calculationPeriod?.name || "",
        badge: {
          text: calculationPeriod?.statusTitle || "",
          bgColor: calculationPeriod?.statusColor,
        },
        description:
          "Selecione as plataformas e insira a quantidade de visualizações das músicas para cada uma delas.",
      }}
      headlineRightComponent={
        <Can permissions={["calculation-period.update"]}>
          <Row>
            {(calculationPeriod?.status === "created" ||
              calculationPeriod?.status === "closed") && (
              <Button variant="ghost" onClick={onClickActiveCalculationPeriod}>
                Ativar apuração
              </Button>
            )}
            {calculationPeriod?.status === "activated" && (
              <Button danger onClick={onClickCloseCalculationPeriod}>
                Encerrar apuração
              </Button>
            )}
          </Row>
        </Can>
      }
    >
      {calculationPeriod?.platforms?.length && (
        <List
          data={calculationPeriod?.platforms?.map((platform) => ({
            title: platform.name,
            avatar: { name: platform?.photo?.name },
            description: platform?.typeTitle,
            rightContent: (
              <>
                <TimeText>
                  {platform?.lastViewsDate
                    ? new Date(platform.lastViewsDate).toLocaleString()
                    : ""}
                </TimeText>
                <Can permissions={["calculation-period.views-insertion"]}>
                  {platform.type === "manual" && (
                    <Button onClick={() => onClickInsert(platform)}>
                      Inserir views
                    </Button>
                  )}
                </Can>
              </>
            ),
          }))}
        />
      )}

      <MakeViewsInsertionFormModal
        onRequestClose={() => makeViewsInsertionFormModalRef?.current?.hide()}
        ref={makeViewsInsertionFormModalRef}
        musics={calculationPeriod?.musics || []}
        onSubmit={onSubmit}
      />
      <Alert ref={alertRef} />
    </DefaultLayout>
  );
}

const TimeText = styled(Label)`
  color: ${({ theme }) => theme.colors.onSurfaceSecondary};
`;
