import styled from "styled-components";

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.spaces[8]};
  padding-bottom: ${({ theme }) => theme.spaces[4]};
  padding-left: ${({ theme }) => theme.spaces[6]};
  padding-right: ${({ theme }) => theme.spaces[6]};
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.text_md};
`;
