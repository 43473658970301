import { AxiosError, AxiosInstance } from "axios";
import { SessionStorage } from "../../../storage/session-storage";

export function addTokenInterceptor(api: AxiosInstance): AxiosInstance {
  api.interceptors.request.use(
    async (config: any) => {
      try {
        const sessionStorage = new SessionStorage();
        const token = sessionStorage.get();

        if (!token) {
          return { ...config };
        }

        return {
          ...config,
          headers: { ...config?.headers, Authorization: `Bearer ${token}` },
        };
      } catch {
        return { ...config };
      }
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  return api;
}
