// import { useEffect } from "react";
import { Spinner } from "../../../components";
// import { useToast } from "../../../hooks/use-toast";
// import { useLogout } from "../../../services/auth/use-logout";
import { Container } from "./log-out.styles";
// import { useNavigate } from "react-router-dom";

export function LogOutPage() {
  // const { mutateAsync: logout } = useLogout();
  // const { errorHandler } = useToast();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   async function handleLogout() {
  //     try {
  //       await logout();
  //       navigate("/");
  //     } catch (error) {
  //       errorHandler(error);
  //     }
  //   }
  //   handleLogout();
  // }, []);

  return (
    <Container>
      <Spinner />
    </Container>
  );
}
