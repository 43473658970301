import { useMutation } from "react-query";
import { api } from "../api";
import { CalculationPeriodsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const closeCalculationPeriodRequest = async (body: {
  calculationPeriodId: string;
}): Promise<void> => {
  await api.patch("api/calculation-period/close", body);
};

export function useCloseCalculationPeriod() {
  return useMutation(closeCalculationPeriodRequest, {
    retry: false,
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(Keys.list());
      queryClient.invalidateQueries(Keys.get(params.calculationPeriodId));
    },
  });
}
