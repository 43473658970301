import { useMutation } from "react-query";
import { api } from "../api";
import { MusicsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const deleteMusicRequest = async (params: {
  musicId: string;
}): Promise<void> => {
  await api.delete("api/music", { params });
};

export function useDeleteMusic() {
  return useMutation(deleteMusicRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
