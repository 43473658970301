import { useState } from "react";
import { TabItem } from "../../atoms";
import { Container } from "./multi-selection-tab.styles";
import { MultiSelectionTabProps } from "./multi-selection-tab.types";

export function MultiSelectionTab(props: MultiSelectionTabProps) {
  const [values, setValues] = useState<string[]>(props.defaultValues || []);

  function calcNewValues(value: string) {
    return values.some((i) => i === value)
      ? values.filter((i) => i !== value)
      : [...values, value];
  }

  function onPressItem(value: string) {
    const newValues = calcNewValues(value);
    setValues(newValues);
    props.onChange && props.onChange(newValues);
  }

  return (
    <Container>
      {props.items.map((item) => (
        <TabItem
          key={item.value}
          selected={values.some((value) => value === item.value)}
          onClick={() => onPressItem(item.value)}
          text={item.text}
        />
      ))}
    </Container>
  );
}
