import { useMutation } from "react-query";
import { api } from "../api";
import { CalculationPeriodsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

type RequestProps = {
  musics: { id: string; viewsCount: number }[];
  platformId: string;
  calculationPeriodId: string;
};

export const viewsInsertionsRequest = async (
  body: RequestProps
): Promise<void> => {
  await api.post("api/calculation-period/views-insertion", body);
};

export function useViewsInsertions() {
  return useMutation(viewsInsertionsRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
