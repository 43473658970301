import styled from "styled-components";
import { TextInput } from "../../atoms";

export const Container = styled.div<{ $numberOfColumns: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spaces[2]};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: ${({ $numberOfColumns }) =>
      Array.from(Array($numberOfColumns).keys()).map(() => "1fr ")};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]};
`;

export const Search = styled(TextInput)`
  max-width: 50%;
`;
