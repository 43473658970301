import { useRef } from "react";
import {
  Button,
  DefaultLayout,
  Alert,
  AlertHandle,
  Can,
  SectionList,
  ListItemProps,
  SectionProps,
  Row,
  IconButton,
} from "../../components";
import { useListMusics } from "../../services/musics/use-list-musics";
import { useListPlatforms } from "../../services/platforms/use-list-platforms";
import { CreateCalculationPeriodFormModal } from "./modal/create-calculation-period-form-modal/create-calculation-period-form-modal";
import { CreateCalculationPeriodFormModalHandle } from "./modal/create-calculation-period-form-modal/create-calculation-period-form-modal.types";
import { CalculationPeriodFormSubmitData } from "./components/calculation-period-form/calculation-period-form.types";
import { useCreateCalculationPeriod } from "../../services/calculation-periods/use-create-calculation-period";
import { useListCalculationPeriods } from "../../services/calculation-periods/use-list-calculation-periods";
import { CalculationPeriod } from "../../types/calculation-period";
import { useDeleteCalculationPeriod } from "../../services/calculation-periods/use-delete-calculation-period";
import { useToast } from "../../hooks/use-toast";
import { UpdateCalculationPeriodFormModal } from "./modal/update-calculation-period-form-modal/update-calculation-period-form-modal";
import {
  OnUpdateCalculationPeriodFormData,
  UpdateCalculationPeriodFormModalHandle,
} from "./modal/update-calculation-period-form-modal/update-calculation-period-form-modal.types";
import { useUpdateCalculationPeriod } from "../../services/calculation-periods/use-update-calculation-period";
import { useNavigate } from "react-router-dom";

export function CalculationPeriodsPage() {
  const { data: platforms } = useListPlatforms();
  const { data: musics } = useListMusics();
  const { errorHandler } = useToast();
  const navigate = useNavigate();
  const { data: calculationPeriods, isLoading } = useListCalculationPeriods();
  const { mutateAsync: deleteCalculationPeriod } = useDeleteCalculationPeriod();
  const { mutateAsync: createCalculationPeriod } = useCreateCalculationPeriod();
  const { mutateAsync: updateCalculationPeriod } = useUpdateCalculationPeriod();
  const alertRef = useRef<AlertHandle>(null);
  const createCalculationPeriodFormModalRef =
    useRef<CreateCalculationPeriodFormModalHandle>(null);
  const updateCalculationPeriodFormModalRef =
    useRef<UpdateCalculationPeriodFormModalHandle>(null);

  async function onClickDeleteCalculationPeriod(
    calculationPeriod: CalculationPeriod
  ) {
    alertRef?.current?.show({
      title: "Deseja mesmo excluir?",
      description: `Deseja mesmo excluir a apuração "${calculationPeriod.name}"?`,
      buttons: [
        {
          children: "Sim, excluir",
          onClick: () => onDeleteCalculationPeriod(calculationPeriod),
        },
        { children: "Não", variant: "ghost" },
      ],
    });
  }

  async function onDeleteCalculationPeriod(item: CalculationPeriod) {
    try {
      await deleteCalculationPeriod({
        calculationPeriodId: item.id,
      });
    } catch (error) {
      errorHandler(error);
    }
  }

  function onClickDetailCalculationPeriod(item: CalculationPeriod) {
    navigate(`detail?id=${item.id}`);
  }

  async function onCreateCalculationPeriod(
    data: CalculationPeriodFormSubmitData
  ) {
    await createCalculationPeriod(data);
  }

  async function onUpdateCalculationPeriod(
    data: OnUpdateCalculationPeriodFormData
  ) {
    await updateCalculationPeriod(data);
  }

  function onClickCreateCalculationPeriod() {
    createCalculationPeriodFormModalRef?.current?.show();
  }

  function onClickUpdateCalculationPeriod(item: CalculationPeriod) {
    updateCalculationPeriodFormModalRef?.current?.show({
      ...item,
      startsAt: item.startsAt.toISOString().split(".")[0] as any,
      endsAt: item.endsAt.toISOString().split(".")[0] as any,
    });
  }

  function itemAdapter(item: CalculationPeriod): ListItemProps {
    return {
      title: item.name,
      description: `De ${item.startsAt.toLocaleString()} até ${item.endsAt.toLocaleString()}`,
      badge: {
        text: item.statusTitle,
        bgColor: item.statusColor,
      },
      rightContent: (
        <Row>
          <Button
            onClick={() => onClickDetailCalculationPeriod(item)}
            variant="ghost"
          >
            Ver detalhes
          </Button>
          <Can permissions={["calculation-period.update"]}>
            <IconButton
              iconName="edit"
              onClick={() => onClickUpdateCalculationPeriod(item)}
            />
          </Can>
          <Can permissions={["calculation-period.delete"]}>
            <IconButton
              iconName="delete"
              onClick={() => onClickDeleteCalculationPeriod(item)}
            />
          </Can>
        </Row>
      ),
    };
  }

  const sections: SectionProps[] = [
    {
      data:
        calculationPeriods
          ?.filter((i) => i.status === "activated")
          ?.map(itemAdapter) || [],
    },
    {
      title: "Histórico",
      data:
        calculationPeriods
          ?.filter((i) => i.status !== "activated")
          ?.map(itemAdapter) || [],
    },
  ];

  return (
    <DefaultLayout
      isLoading={isLoading}
      headlineRightComponent={
        <Can permissions={["calculation-period.create"]}>
          <Button
            leftIconName="add"
            onClick={onClickCreateCalculationPeriod}
            variant="outline"
          >
            Adicionar apuração
          </Button>
        </Can>
      }
      headline={{ title: "Apuração" }}
    >
      <SectionList sections={sections} />

      <CreateCalculationPeriodFormModal
        platforms={platforms || []}
        musics={musics || []}
        ref={createCalculationPeriodFormModalRef}
        onRequestClose={() =>
          createCalculationPeriodFormModalRef?.current?.hide()
        }
        onSubmit={onCreateCalculationPeriod}
      />
      <UpdateCalculationPeriodFormModal
        platforms={platforms || []}
        musics={musics || []}
        ref={updateCalculationPeriodFormModalRef}
        onRequestClose={() =>
          updateCalculationPeriodFormModalRef?.current?.hide()
        }
        onSubmit={onUpdateCalculationPeriod}
      />
      <Alert ref={alertRef} />
    </DefaultLayout>
  );
}
