import { useMutation } from "react-query";
import { api } from "../api";
import { ParticipantsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const updateParticipantRequest = async (body: {
  [key: string]: any;
}): Promise<void> => {
  const formData = new FormData();
  Object.keys(body).forEach((key: string) => {
    if (body[key]) {
      formData.append(key, body[key]);
    }
  });
  await api.put("api/participant", formData);
};

export function useUpdateParticipant() {
  return useMutation(updateParticipantRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
