import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
`;

export const HelpText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.text_xs};
  color: ${({ theme }) => theme.colors.onSurfaceSecondary};
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.onSurfaceSecondary};
  border-radius: ${({ theme }) => theme.radii.md};
  padding: ${({ theme }) => theme.spaces[4]};
  font-size: ${({ theme }) => theme.fontSizes.text_lg};
  outline: none;
  color: ${({ theme }) => theme.colors.onSurfacePrimary};
  color-scheme: dark;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
