import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { DashboardResume } from "../../types/dashboard";
import { DashboardKeys as Keys } from "./state-keys";

const processor = (data: DashboardResume): DashboardResume => {
  return data;
};

export const useGetDashboardResume = (
  props?: UseFetchHooks<DashboardResume, { calculationPeriodId: string }>
) => {
  const { context, params, config } = props || {};
  return useFetch<DashboardResume>({
    key: Keys.resume(params?.calculationPeriodId!),
    url: "api/dashboard",
    params,
    context,
    config,
    processor,
  });
};
