import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { CalculationPeriodDetail } from "../../types/calculation-period";
import { platformTypeAdapter } from "../platforms/helpers/type-adapter";
import { statusAdapter } from "./helpers/status-adapter";
import { CalculationPeriodsKeys as Keys } from "./state-keys";

const processor = (data: CalculationPeriodDetail): CalculationPeriodDetail => {
  return {
    ...data,
    startsAt: new Date(data.startsAt),
    endsAt: new Date(data.endsAt),
    statusColor: statusAdapter[data.status].color,
    statusTitle: statusAdapter[data.status].title,
    platforms: data?.platforms?.map((platform) => ({
      ...platform,
      typeTitle: platformTypeAdapter[platform.type].title,
      typeColor: platformTypeAdapter[platform.type].color,
    })),
  };
};

export const useGetCalculationPeriod = (
  props?: UseFetchHooks<CalculationPeriodDetail, { id: string }>
) => {
  const { context, params, config } = props || {};
  return useFetch<CalculationPeriodDetail>({
    key: Keys.get(params?.id!),
    url: "api/calculation-period/detail",
    params,
    context,
    config,
    processor,
  });
};
