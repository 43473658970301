import { useLocation } from "react-router-dom";
import { MenuItem } from "../../molecules";
import { sideBarData } from "./side-bar.data";
import { Container } from "./side-bar.styles";
import { Can } from "../../atoms";
import { SideBarProps } from "./side-bar.types";

export function SideBar({ isOpen }: SideBarProps) {
  const { pathname } = useLocation();

  return (
    <Container $isOpen={isOpen}>
      {sideBarData?.map((item) => (
        <Can key={item.title} permissions={item.permissions}>
          <MenuItem
            icon={item.icon}
            selected={pathname.includes(item.href)}
            title={item.title}
            to={item.href}
          />
        </Can>
      ))}
    </Container>
  );
}
