import { useMutation } from "react-query";
import { getCookie } from "../../lib/get-cookie";
import { api } from "../api";
import { SessionStorage } from "../../storage/session-storage";

export const loginGloboCallbackRequest = async (params: {
  code: string | null;
  session_state: string | null;
}): Promise<void> => {
  const { data } = await api.post("/api/auth/sign-in/globo/callback", {
    key: getCookie("SESS_VERIFIER"),
    ...params,
  });
  const sessionStorage = new SessionStorage();
  sessionStorage.set(data.token);
};

export function useLoginGloboCallback() {
  return useMutation(loginGloboCallbackRequest);
}
