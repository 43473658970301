import styled from "styled-components";

const SIDE_BAR_WIDTH = "260px";

export const Container = styled.aside<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  max-width: ${({ $isOpen }) => ($isOpen ? SIDE_BAR_WIDTH : "0px")};
  box-sizing: border-box;
  overflow-y: auto;
  padding-right: ${({ $isOpen }) => ($isOpen ? "32px" : "0px")};
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: 0.3s ease all;
  transform: ${({ $isOpen }) =>
    $isOpen ? "translateX(0)" : `translateX(-${SIDE_BAR_WIDTH})`};

  position: absolute;
  z-index: 9999;
  box-shadow: ${({ theme }) => theme.shadows["3xl"]};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: relative;
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.radii.sm};
    background-color: ${({ theme }) => theme.colors.onBackgroundDisabled};
  }

  &:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
  }
`;
