import { Ref, forwardRef } from "react";
import { Modal } from "../../../../components";
import {
  CreateParticipantFormModalProps,
  CreateParticipantFormModalHandle,
} from "./create-participant-form-modal.types";
import { ParticipantForm } from "../../components/participant-form/participant-form";

function Component(
  props: CreateParticipantFormModalProps,
  ref: Ref<CreateParticipantFormModalHandle>
): JSX.Element {
  return (
    <Modal headline={{ title: "Adicionar participante" }} ref={ref}>
      <ParticipantForm {...props} />
    </Modal>
  );
}

export const CreateParticipantFormModal = forwardRef<
  CreateParticipantFormModalHandle,
  CreateParticipantFormModalProps
>(Component);
