import { AnalyticsHistoric } from "../../types/analytics-historic";
import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { AnalyticsKeys as Keys } from "./state-keys";

type ParamsProps = {
  musicIds: string[];
  calculationPeriodId: string;
  platformId: string;
};

const processor = (data: any): any => {
  return data;
};

export const useGetAnalyticsHistoric = (
  props?: UseFetchHooks<AnalyticsHistoric[], ParamsProps>
) => {
  const { context, params, config } = props || {};
  return useFetch<AnalyticsHistoric[]>({
    key: Keys.historic(
      params?.calculationPeriodId!,
      params?.platformId!,

      String(params?.musicIds!)
    ),
    url: "api/analytics/historic",
    params,
    context,
    config,
    processor,
  });
};
