import { TextInput, Button } from "../../../../../components";
import { Content, Footer } from "../calculation-period-form.styles";
import { DateInput, DatesRow } from "./geral-info-block.styles";
import { GeralInfoBlockProps } from "./geral-info-block.types";

export function GeralInfoBlock(props: GeralInfoBlockProps) {
  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    const formData = new FormData(evt.target as any);
    const data = Object.fromEntries(formData) as any;
    await props.onSubmit(data);
  }

  return (
    <Content onSubmit={onSubmit}>
      <TextInput
        label="Nome"
        placeholder="Digite aqui"
        name="name"
        required
        defaultValue={props.defaultValues?.name}
      />
      <DatesRow>
        <DateInput
          label="Começa em"
          name="startsAt"
          required
          type="datetime-local"
          defaultValue={props.defaultValues?.startsAt}
        />
        <DateInput
          label="Termina em"
          name="endsAt"
          required
          type="datetime-local"
          defaultValue={props.defaultValues?.endsAt}
        />
      </DatesRow>

      <Footer>
        <Button isLoading={props.isLoading} type="submit">
          Continuar
        </Button>
      </Footer>
    </Content>
  );
}
