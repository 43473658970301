import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: ${({ theme }) => theme.spaces[4]};
`;

export const Image = styled.img`
  height: 150px;
  width: 150px;
  border-radius: ${({ theme }) => theme.radii.round};
  object-fit: cover;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
`;
