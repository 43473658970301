import { useMutation } from "react-query";
import { api } from "../api";
import { CalculationPeriodsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

type RequestProps = {
  calculationPeriodId: string;
  name: string;
  startsAt: string;
  endsAt: string;
  musics: string[];
  platforms: string[];
};

export const updateCalculationPeriodRequest = async (
  body: RequestProps
): Promise<void> => {
  await api.put("api/calculation-period", body);
};

export function useUpdateCalculationPeriod() {
  return useMutation(updateCalculationPeriodRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
