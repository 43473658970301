import { Ref, forwardRef } from "react";
import { Modal } from "../../../../components";
import {
  CreatePlatformFormModalProps,
  CreatePlatformFormModalHandle,
} from "./create-platform-form-modal.types";
import { PlatformForm } from "../../components/platform-form/platform-form";

function Component(
  props: CreatePlatformFormModalProps,
  ref: Ref<CreatePlatformFormModalHandle>
): JSX.Element {
  return (
    <Modal headline={{ title: "Adicionar plataforma" }} ref={ref}>
      <PlatformForm {...props} />
    </Modal>
  );
}

export const CreatePlatformFormModal = forwardRef<
  CreatePlatformFormModalHandle,
  CreatePlatformFormModalProps
>(Component);
