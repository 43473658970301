import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

type useSearchProps<T> = {
  data: Array<T>;
  search: string;
  searchableKeys: Array<keyof T>;
};

export function useSearch<T>(props: useSearchProps<T>) {
  const [filteredData, setFilteredData] = useState(props.data);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), [
    props.data,
  ]);

  function handleDebounceFn(inputValue: string) {
    //   if (onSearch) {
    //     return onSearch(inputValue);
    //   }
    if (!inputValue) {
      return setFilteredData(props.data);
    }

    const filtered = props.data?.filter((item) => {
      const conditions: string[] = [];
      props.searchableKeys?.forEach((searchableKey) => {
        conditions.push(String(item[searchableKey]));
      });
      return conditions.some((cond) =>
        cond.toLowerCase().includes(inputValue?.toLowerCase())
      );
    });
    setFilteredData(filtered);
  }

  useEffect(() => {
    debounceFn(props.search);
    setFilteredData(props.data);
  }, [props.data, debounceFn, props.search]);

  return { filteredData };
}
