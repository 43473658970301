import { Label } from "../label";
import { Container, HelpText, Input } from "./text-input.styles";
import { TextInputProps } from "./text-input.types";

export function TextInput({ label, ...props }: TextInputProps) {
  return (
    <Container>
      <Label htmlFor={props.name}>{label}</Label>
      <Input {...props} />
      <HelpText>{props.helpText}</HelpText>
    </Container>
  );
}
