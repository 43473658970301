import ReactDOM from "react-dom/client";
// import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, lightTheme } from "./styles";
import { Routes } from "./routes/routes";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/query-client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Routes />
      <ToastContainer />
    </ThemeProvider>
  </QueryClientProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
