import { Badge } from "../badge";
import { Container, Description, Title } from "./headline.styles";
import { HeadlineProps } from "./headline.types";

export function Headline(props: HeadlineProps) {
  return (
    <Container>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
      {props.badge && <Badge {...props.badge} />}
    </Container>
  );
}
