import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { Role } from "../../types/role";
import { AuthKeys as Keys } from "./state-keys";

const processor = (data: Role[]): Role[] => {
  return data;
};

export const useListRoles = (props?: UseFetchHooks<Role[]>) => {
  const { context, params, config } = props || {};
  return useFetch<Role[]>({
    key: Keys.listRoles(),
    url: "api/auth/role",
    params,
    context,
    config,
    processor,
  });
};
