import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { Music } from "../../types/music";
import { MusicsKeys as Keys } from "./state-keys";

const processor = (data: Music[]): Music[] => {
  return data;
};

export const useListMusics = (props?: UseFetchHooks<Music[]>) => {
  const { context, params, config } = props || {};
  return useFetch<Music[]>({
    key: Keys.list(),
    url: "api/music",
    params,
    context,
    config,
    processor,
  });
};
