import { useDownloadFile } from "../../../services/file/download-file";
import { Avatar } from "../avatar";
import { PrivateAvatarProps } from "./private-avatar.types";
import { LoadingContainer } from "./private-avatar.styles";

export function PrivateAvatar(props: PrivateAvatarProps) {
  const { data } = useDownloadFile({
    params: { filename: props.name },
    config: {
      staleTime: 5 * 60 * 1000, // 5 minutos
      cacheTime: 30 * 60 * 1000, // 30 minutos
    },
  });

  if (!data) return <LoadingContainer />;

  return <Avatar src={data?.src} />;
}
