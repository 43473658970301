import { createGlobalStyle } from "styled-components";
import GlobotipoTextoBold from "../assets/fonts/GlobotipoTexto-Bold.ttf";
import GlobotipoTextoBlack from "../assets/fonts/GlobotipoTexto-Black.ttf";
import GlobotipoTextoRegular from "../assets/fonts/GlobotipoTexto-Regular.ttf";
import GlobotipoTextoLight from "../assets/fonts/GlobotipoTexto-Light.ttf";

export const GlobalStyle = createGlobalStyle`
   @font-face {
        font-family: GlobotipoTexto-Black;
        src: local(GlobotipoTexto-Black),
        url(${GlobotipoTextoBlack as any}) format('ttf');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: 'GlobotipoTexto-Bold';
        src: local('GlobotipoTexto-Bold'),
        url(${GlobotipoTextoBold as any}) format('ttf');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'GlobotipoTexto-Regular';
        src: local('GlobotipoTexto-Regular'),
        url(${GlobotipoTextoRegular as any}) format('ttf');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'GlobotipoTexto-Light';
        src: local('GlobotipoTexto-Light'),
        url(${GlobotipoTextoLight as any}) format('ttf');
        font-weight: 300;
        font-style: normal;
    }

    /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    body {
        margin: 0;
        background-color: #061323;
        font-family: GlobotipoTexto-Bold, -apple-system, BlinkMacSystemFont,
            "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
            "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    button, input, option, select, a {
        font-family: GlobotipoTexto-Bold, -apple-system, BlinkMacSystemFont,
            "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
            "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
   
`;
