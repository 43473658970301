import { useMutation } from "react-query";
import { api } from "../api";
import { CalculationPeriodsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

type RequestProps = {
  name: string;
  startsAt: string;
  endsAt: string;
  musics: string[];
  platforms: string[];
};

export const createCalculationPeriodRequest = async (
  body: RequestProps
): Promise<void> => {
  await api.post("api/calculation-period", body);
};

export function useCreateCalculationPeriod() {
  return useMutation(createCalculationPeriodRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
