import { PlatformType } from "../../../types/platform";

type TypeAdapterProps = {
  [key in PlatformType]: { title: string; color: string };
};

export const platformTypeAdapter: TypeAdapterProps = {
  automatic: { title: "Automática", color: "#FDD92D" },
  manual: { title: "Manual", color: "#b2bfca" },
};
