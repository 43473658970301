import { UserFormProps, UserFormSubmitData } from "./user-form.types";
import { Button, Select, TextInput } from "../../../../components";
import { Footer, Content, Container } from "./user-form.styles";
import { useToast } from "../../../../hooks/use-toast";
import { useState } from "react";

export function UserForm(props: UserFormProps) {
  const { errorHandler } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    try {
      evt.preventDefault();
      setIsLoading(true);
      const formData = new FormData(evt.target as any);
      const data = Object.fromEntries(formData) as UserFormSubmitData;
      await props.onSubmit(data);
      props.onRequestClose();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Content onSubmit={onSubmit}>
        <TextInput
          label="Nome"
          placeholder="Digite aqui"
          name="name"
          required
          defaultValue={props.defaultValues?.name}
        />
        <TextInput
          label="E-mail"
          placeholder="Digite aqui"
          name="email"
          type="email"
          defaultValue={props.defaultValues?.email}
          required
        />
        <Select
          options={props.roles.map((item) => ({
            title: item.name,
            value: item.id,
          }))}
          label="Nível de acesso"
          name="role"
          defaultValue={props.defaultValues?.role}
          required
        />
        <Footer>
          <Button type="button" variant="ghost" onClick={props.onRequestClose}>
            Cancelar
          </Button>
          <Button isLoading={isLoading} type="submit">
            Salvar
          </Button>
        </Footer>
      </Content>
    </Container>
  );
}
