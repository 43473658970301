export type StorageKeys = "@hitmaker/session";

export class BaseStorage<T> {
  constructor(readonly key: StorageKeys) {}

  set(data: T): void {
    window.localStorage.setItem(this.key, JSON.stringify(data));
  }

  get(): T | null {
    const data = window.localStorage.getItem(this.key);
    return data ? JSON.parse(data) : null;
  }

  remove(): void {
    window.localStorage.removeItem(this.key);
  }
}
