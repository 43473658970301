import styled, { css } from "styled-components";
import { ModalSizes } from "./modal.types";

export const Container = styled.div<{ $visible: boolean }>`
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const variantStyles = (size: ModalSizes) =>
  ({
    sm: css`
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 45vw;
      }
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        width: 40vw;
      }
    `,
    md: css`
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 45vw;
      }
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        width: 40vw;
      }
    `,
    lg: css`
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 80vw;
      }
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        width: 70vw;
      }
    `,
  }[size]);

export const Content = styled.div<{ $size: ModalSizes }>`
  overflow: hidden;
  flex-direction: column;
  position: relative;
  width: 90vw;
  display: flex;
  max-height: 80%;

  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.surface};

  ${({ $size }) => variantStyles($size)}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces[8]};
  padding-bottom: ${({ theme }) => theme.spaces[4]};
`;

export const Footer = styled.footer`
  /* padding: ${({ theme }) => theme.spaces[7]}; */
`;

export const Body = styled.div`
  padding: ${({ theme }) => theme.spaces[8]};
`;
