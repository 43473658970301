import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { DashboardPlatform } from "../../types/dashboard";
import { platformTypeAdapter } from "../platforms/helpers/type-adapter";
import { DashboardKeys as Keys } from "./state-keys";

const processor = (data: DashboardPlatform): DashboardPlatform => {
  return {
    ...data,
    platforms: data?.platforms?.map((platform) => ({
      ...platform,
      typeTitle: platformTypeAdapter[platform.type].title,
      typeColor: platformTypeAdapter[platform.type].color,
    })),
  };
};

export const useGetDashboardPlatforms = (
  props?: UseFetchHooks<
    DashboardPlatform,
    { calculationPeriodId: string; musicId: string }
  >
) => {
  const { context, params, config } = props || {};
  return useFetch<DashboardPlatform>({
    key: Keys.platforms(params?.calculationPeriodId!, params?.musicId!),
    url: "api/dashboard/platforms",
    params,
    context,
    config,
    processor,
  });
};
