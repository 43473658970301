import { Button } from "../../../components";

export function SignIn() {
  async function handleLogin() {
    const response = await fetch(
      "http://localhost:3000/api/auth/sign-in/globo"
    );
    const { key, endpoint } = await response.json();
    document.cookie = `SESS_VERIFIER=${key};max-age=604800;`;
    window.location.href = endpoint;
  }

  return <Button onClick={handleLogin}>Login</Button>;

  // return <button onClick={handleLogin}>Login</button>;
}
