import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spaces[5]};
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.background : "transparent"};
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.primary : theme.colors.onSurfacePrimary};
  border-left: ${({ $selected, theme }) =>
    $selected ? `2px solid ${theme.colors.primary}` : "none"};

  text-decoration: none;
  gap: ${({ theme }) => theme.spaces[4]};

  &:hover {
    transition: 0.4s ease all;
    opacity: 0.6;
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const Title = styled.strong`
  /* font-family: ${({ theme }) => theme.fonts.globotipo_bold}; */
  /* color: ${({ theme }) => theme.colors.onSurfacePrimary}; */
  /* font-size: ${({ theme }) => theme.fontSizes.text_md}; */
`;
