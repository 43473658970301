import { Icon } from "../../atoms";
import { Container, Title } from "./menu-item.styles";
import { MenuItemProps } from "./menu-item.types";

export function MenuItem({ selected, title, ...props }: MenuItemProps) {
  return (
    <Container {...props} $selected={selected}>
      <Icon name={props.icon} />
      <Title>{title}</Title>
    </Container>
  );
}
