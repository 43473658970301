import { Container, Description, Title } from "./alert-content.styles";
import { AlertContentProps } from "./alert-content.types";

export function AlertContent(props: AlertContentProps) {
  return (
    <Container>
      <Title>{props.title}</Title>
      {props.description && <Description>{props.description}</Description>}
    </Container>
  );
}
