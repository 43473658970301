import styled from "styled-components";
import { IconButton } from "../../atoms";

export const Container = styled.header`
  display: flex;
  min-height: 60px;
  background-color: ${({ theme }) => theme.colors.surface};
  align-items: center;
  padding: 0px ${({ theme }) => theme.spaces[7]};
  justify-content: space-between;
`;

export const MenuButton = styled(IconButton)``;

export const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces[4]};
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
`;
