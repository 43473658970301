import { Ref, forwardRef } from "react";
import { Modal } from "../../../../components";
import { UserForm } from "../../components/user-form/user-form";
import {
  CreateUserFormModalProps,
  CreateUserFormModalHandle,
} from "./create-user-form-modal.types";

function Component(
  props: CreateUserFormModalProps,
  ref: Ref<CreateUserFormModalHandle>
): JSX.Element {
  return (
    <Modal headline={{ title: "Adicionar usuário" }} ref={ref}>
      <UserForm {...props} />
    </Modal>
  );
}

export const CreateUserFormModal = forwardRef<
  CreateUserFormModalHandle,
  CreateUserFormModalProps
>(Component);
