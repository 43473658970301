import { useEffect, useMemo } from "react";
import { DefaultLayout, Select, MultiSelectionTab } from "../../components";
import { useQueryParams } from "../../hooks/use-query-params";
import { useListCalculationPeriods } from "../../services/calculation-periods/use-list-calculation-periods";
import { LineChart } from "../../components/molecules/line-chart";
import { useGetAnalyticsHistoric } from "../../services/analytics/use-get-analytics-historic";
import { useGetCalculationPeriod } from "../../services/calculation-periods/use-get-calculation-period";
import { useListPlatforms } from "../../services/platforms/use-list-platforms";

export function AnalyticsPage() {
  const { add, get } = useQueryParams();
  const calculationPeriodId = get("calculationPeriodId");
  const platformId = get("platformId");
  const musicIdsParam = get("musicIds");
  const musicIds = musicIdsParam ? JSON.parse(musicIdsParam) : [];

  const { data: calculationPeriods, isLoading: isLoadingCalculationPeriods } =
    useListCalculationPeriods();
  const { data: platforms, isLoading: isLoadingPlatforms } = useListPlatforms();
  const { data: calculationPeriod, isLoading: isLoadingCalculationPeriod } =
    useGetCalculationPeriod({
      params: { id: calculationPeriodId! },
      config: { enabled: !!calculationPeriodId },
    });
  const { data: historic } = useGetAnalyticsHistoric({
    params: {
      calculationPeriodId: calculationPeriodId!,
      platformId: platformId!,
      musicIds,
    },
    config: { enabled: !!calculationPeriodId && !!platformId },
  });

  useEffect(() => {
    function setDefaultCalculationPeriod() {
      if (calculationPeriodId) return;
      const activatedCalculationPeriod = calculationPeriods?.find(
        (item) => item.status === "activated"
      );
      if (activatedCalculationPeriod) {
        add("calculationPeriodId", activatedCalculationPeriod.id);
      } else if (calculationPeriods?.length) {
        add("calculationPeriodId", calculationPeriods[0].id);
      }
    }
    setDefaultCalculationPeriod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationPeriods]);

  function updateMusicsIdsParams(musicIds: string[]) {
    add("musicIds", JSON.stringify(musicIds));
  }

  const selectOptions =
    calculationPeriods?.map((item) => ({
      title: item.name,
      value: item.id,
    })) || [];

  const platformsOptions =
    platforms?.map((item) => ({
      title: item.name,
      value: item.id,
    })) || [];

  const chartData = useMemo(() => {
    return historic?.map((historicItem) => ({
      id: historicItem.participant,
      data: historicItem.data?.map((dataItem) => ({
        x: new Date(dataItem.date).toLocaleString(),
        y: dataItem.views,
      })),
    }));
  }, [historic]);

  const musicsTab = useMemo(() => {
    return calculationPeriod?.musics?.map((music) => ({
      value: music.id,
      text: music.participant?.name,
    }));
  }, [calculationPeriod]);

  const isLoading =
    isLoadingCalculationPeriods ||
    isLoadingCalculationPeriod ||
    isLoadingPlatforms;

  return (
    <DefaultLayout
      isLoading={isLoading}
      headline={{
        title: "Análises",
        description: "Gráficos para acompanhamento de views",
      }}
      headlineRightComponent={
        <>
          {calculationPeriodId && (
            <>
              <Select
                label="Apuração"
                defaultValue={calculationPeriodId || undefined}
                onChange={(evt) => add("calculationPeriodId", evt.target.value)}
                options={selectOptions}
              />
              <Select
                label="Plataforma"
                defaultValue={platformId || undefined}
                onChange={(evt) => add("platformId", evt.target.value)}
                options={platformsOptions}
              />
            </>
          )}
        </>
      }
    >
      <MultiSelectionTab
        defaultValues={musicIds}
        onChange={updateMusicsIdsParams}
        items={musicsTab || []}
      />
      <LineChart data={chartData || []} />
    </DefaultLayout>
  );
}
