import { Icon } from '../../atoms'
import {
  IndicatorBox,
  IndicatorBoxContent,
  IndicatorText,
  Separator,
  WrapperIndicator,
  WrapperIndicators,
} from './steper-indicators.styles'
import { SteperIndicatorsProps } from './steper-indicators.types'
import { Fragment } from 'react'

export function SteperIndicators({ steps, index }: SteperIndicatorsProps): JSX.Element {
  return (
    <WrapperIndicators>
      {steps?.map((step, position) => {
        const isDisabled = position > index
        const isConcluded = position < index
        return (
          <Fragment key={step.title}>
            <WrapperIndicator>
              <IndicatorBox $disabled={isDisabled} $concluded={isConcluded}>
                <IndicatorBoxContent>
                  {position < index ? <Icon name='check' size={20} /> : position + 1}
                </IndicatorBoxContent>
              </IndicatorBox>
              <IndicatorText>{step.title}</IndicatorText>
            </WrapperIndicator>
            {position < steps?.length - 1 && <Separator $concluded={isConcluded} />}
          </Fragment>
        )
      })}
    </WrapperIndicators>
  )
}
