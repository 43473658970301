import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { Platform } from "../../types/platform";
import { platformTypeAdapter } from "./helpers/type-adapter";
import { PlatformsKeys as Keys } from "./state-keys";

const processor = (data: Platform[]): Platform[] => {
  return data.map((platform) => ({
    ...platform,
    typeTitle: platformTypeAdapter[platform.type].title,
    typeColor: platformTypeAdapter[platform.type].color,
  }));
};

export const useListPlatforms = (
  props?: UseFetchHooks<Platform[], { type?: string }>
) => {
  const { context, params, config } = props || {};
  return useFetch<Platform[]>({
    key: Keys.list(params?.type),
    url: "api/platform",
    params,
    context,
    config,
    processor,
  });
};
