import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal, ModalHandle } from "../../../../components";
import {
  UpdateCalculationPeriodFormModalHandle,
  UpdateCalculationPeriodFormModalProps,
} from "./update-calculation-period-form-modal.types";
import { CalculationPeriodForm } from "../../components/calculation-period-form/calculation-period-form";
import { CalculationPeriodFormSubmitData } from "../../components/calculation-period-form/calculation-period-form.types";
import { CalculationPeriod } from "../../../../types/calculation-period";

function Component(
  props: UpdateCalculationPeriodFormModalProps,
  ref: Ref<UpdateCalculationPeriodFormModalHandle>
): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [defaultValues, setDefaultValues] = useState<CalculationPeriod>();

  function show(data: CalculationPeriod) {
    setDefaultValues(data);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  async function onSubmit(data: CalculationPeriodFormSubmitData) {
    return props.onSubmit({ ...data, calculationPeriodId: defaultValues?.id! });
  }

  return (
    <Modal size="lg" headline={{ title: "Editar apuração" }} ref={modalRef}>
      <CalculationPeriodForm
        {...props}
        submitButtonText="Editar"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

export const UpdateCalculationPeriodFormModal = forwardRef<
  UpdateCalculationPeriodFormModalHandle,
  UpdateCalculationPeriodFormModalProps
>(Component);
