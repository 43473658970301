import {
  ParticipantFormProps,
  ParticipantFormSubmitData,
} from "./participant-form.types";
import { Button, Select, TextInput } from "../../../../components";
import { Footer, Content, Container } from "./participant-form.styles";
import { useToast } from "../../../../hooks/use-toast";
import { useState } from "react";

export function ParticipantForm({
  fileRequired = true,
  ...props
}: ParticipantFormProps) {
  const { errorHandler } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    try {
      evt.preventDefault();
      setIsLoading(true);
      const formData = new FormData(evt.target as any);
      const data = Object.fromEntries(formData) as ParticipantFormSubmitData;
      await props.onSubmit(data);
      props.onRequestClose();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Content onSubmit={onSubmit}>
        <TextInput
          label="Nome"
          placeholder="Digite aqui"
          name="name"
          required
          defaultValue={props.defaultValues?.name}
        />
        <TextInput
          label="Foto"
          name="file"
          type="file"
          required={fileRequired}
          accept="image/*"
        />
        <Select
          label="Status"
          options={[
            { title: "Ativado", value: "activated" },
            { title: "Eliminado", value: "eliminated" },
          ]}
          name="status"
          defaultValue={props.defaultValues?.status}
          required
        />
        <Footer>
          <Button type="button" variant="ghost" onClick={props.onRequestClose}>
            Cancelar
          </Button>
          <Button isLoading={isLoading} type="submit">
            Salvar
          </Button>
        </Footer>
      </Content>
    </Container>
  );
}
