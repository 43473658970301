import styled from "styled-components";

export const WrapperViews = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.spaces[1]};
`;

export const Views = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.display_xs};
  color: ${({ theme }) => theme.colors.whiteA900};
`;
