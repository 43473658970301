import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[8]};
`;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]};
  flex-direction: row-reverse;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.onSurfacePrimary};
`;
