import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces[1]};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
  font-size: ${({ theme }) => theme.fontSizes.display_xs};
  margin: 0px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes.display_md};
  }
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.onBackgroundSecondary};
  font-size: ${({ theme }) => theme.fontSizes.text_sm};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes.text_lg};
  }
`;
