import { SpaceProps } from "../../../types/theme";

export const spaces: SpaceProps = {
  0: "0px",
  1: "2px",
  2: "4px",
  3: "8px",
  4: "12px",
  5: "16px",
  6: "20px",
  7: "24px",
  8: "32px",
  9: "40px",
  10: "48px",
  11: "56px",
  12: "64px",
  13: "80px",
  14: "96px",
};
