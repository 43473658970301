import styled from "styled-components";
import LogoImg from "../../../assets/images/logo.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  gap: ${({ theme }) => theme.spaces[12]};
`;

export const Logo = styled.img.attrs(() => ({
  src: LogoImg as any,
  alt: "logo",
}))`
  height: 200px;
  width: 200px;
`;

export const WrapperMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]};
`;

export const Message = styled.strong`
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
  font-size: ${({ theme }) => theme.fontSizes.display_sm};
  text-align: center;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
  font-size: ${({ theme }) => theme.fontSizes.text_xl};
  text-align: center;
`;
