import { Ref, forwardRef } from "react";
import { Modal } from "../../../../components";
import {
  FiltersLogsModalProps,
  FiltersLogsModalHandle,
} from "./filters-logs-modal.types";
import { FiltersLogsForm } from "../../components/filters-logs/filters-logs-form";

function Component(
  props: FiltersLogsModalProps,
  ref: Ref<FiltersLogsModalHandle>
): JSX.Element {
  return (
    <Modal headline={{ title: "Filtro de logs" }} ref={ref}>
      <FiltersLogsForm {...props} />
    </Modal>
  );
}

export const FiltersLogsModal = forwardRef<
  FiltersLogsModalHandle,
  FiltersLogsModalProps
>(Component);
