import { useState } from "react";
import {
  Label,
  SelectionList,
  Button,
  SelectionListOption,
} from "../../../../../components";
import {
  Content,
  Footer,
  SelectionListWrapper,
} from "../calculation-period-form.styles";

import { MusicsBlockProps } from "./musics-block.types";
import { AlertContent } from "../../../../../components/molecules/alert-content";

export function MusicsBlock(props: MusicsBlockProps) {
  const [error, setError] = useState<string>();
  const [musicsIds, setMusicsIds] = useState<string[]>(
    props.defaultValues?.musics || []
  );

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    if (musicsIds.length === 0) {
      setError("Selecione pelo menos uma música.");
    } else {
      await props.onSubmit({ musics: musicsIds });
    }
  }

  const msuicsOptions: SelectionListOption[] = props.musics.map((music) => ({
    title: music.name,
    value: music.id,
    description: music?.participant?.name,
  }));

  return (
    <Content onSubmit={onSubmit}>
      <Label>Músicas</Label>
      <SelectionListWrapper>
        <SelectionList
          numberOfColumns={4}
          defaultValues={musicsIds}
          options={msuicsOptions}
          onChange={setMusicsIds}
        />
      </SelectionListWrapper>
      {error && <AlertContent description={error} />}

      <Footer>
        <Button type="button" variant="ghost" onClick={props.onPressCancel}>
          Voltar
        </Button>
        <Button isLoading={props.isLoading} type="submit">
          Salvar
        </Button>
      </Footer>
    </Content>
  );
}
