import { Container, Content } from "./icon-button.styles";
import { IconButtonProps } from "./icon-button.types";

export function IconButton({
  iconName,
  variant = "ghost",
  ...props
}: IconButtonProps) {
  return (
    <Container $variant={variant} {...props}>
      <Content name={iconName} />
    </Container>
  );
}
