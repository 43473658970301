import { useRef } from "react";
import {
  Button,
  DefaultLayout,
  IconButton,
  Row,
  Alert,
  AlertHandle,
  Can,
  List,
} from "../../components";
import { useToast } from "../../hooks/use-toast";
import { useListMusics } from "../../services/musics/use-list-musics";
import { Music } from "../../types/music";
import { CreateMusicFormModal } from "./modal/create-music-form-modal/create-music-form-modal";
import { CreateMusicFormModalHandle } from "./modal/create-music-form-modal/create-music-form-modal.types";
import { MusicFormSubmitData } from "./components/music-form/music-form.types";
import { useListParticipants } from "../../services/participants/use-list-participants";
import { useCreateMusic } from "../../services/musics/use-create-music";
import { useDeleteMusic } from "../../services/musics/use-delete-music";
import { UpdateMusicFormModal } from "./modal/update-music-form-modal/update-music-form-modal";
import {
  OnUpdateMusicFormData,
  UpdateMusicFormModalHandle,
} from "./modal/update-music-form-modal/update-music-form-modal.types";
import { useUpdateMusic } from "../../services/musics/use-update-music";
import { useListPlatforms } from "../../services/platforms/use-list-platforms";

export function MusicsPage() {
  const { data: musics } = useListMusics();
  const { data: automaticPlatforms } = useListPlatforms({
    params: { type: "automatic" },
  });
  const createMusicFormModalRef = useRef<CreateMusicFormModalHandle>(null);
  const updateMusicFormModalRef = useRef<UpdateMusicFormModalHandle>(null);
  const alertRef = useRef<AlertHandle>(null);
  const { data: participants } = useListParticipants();
  const { mutateAsync: createMusic } = useCreateMusic();
  const { mutateAsync: updateMusic } = useUpdateMusic();
  const { mutateAsync: deleteMusic } = useDeleteMusic();
  const { errorHandler } = useToast();

  async function onClickDeleteMusic(music: Music) {
    alertRef?.current?.show({
      title: "Deseja mesmo excluir?",
      description: `Deseja mesmo excluir a música "${music.name}"?`,
      buttons: [
        {
          children: "Sim, excluir",
          onClick: () => onDeleteMusic(music),
        },
        { children: "Não", variant: "ghost" },
      ],
    });
  }

  function onClickUpdateMusic(music: Music) {
    updateMusicFormModalRef?.current?.show(music);
  }

  function onClickCreateMusic() {
    createMusicFormModalRef?.current?.show();
  }

  async function onCreateMusic(data: MusicFormSubmitData) {
    await createMusic(data);
  }

  async function onUpdateMusic(music: OnUpdateMusicFormData) {
    await updateMusic(music);
  }

  async function onDeleteMusic(music: Music) {
    try {
      await deleteMusic({ musicId: music.id });
    } catch (error) {
      errorHandler(error);
    }
  }

  return (
    <DefaultLayout
      headlineRightComponent={
        <Can permissions={["music.create"]}>
          <Button
            leftIconName="add"
            onClick={onClickCreateMusic}
            variant="outline"
          >
            Adicionar música
          </Button>
        </Can>
      }
      headline={{ title: "Músicas" }}
    >
      {musics?.length && (
        <List
          data={musics?.map((music) => ({
            title: music.name,
            description: music.participant.name,
            rightContent: (
              <Row>
                <Can permissions={["music.update"]}>
                  <IconButton
                    iconName="edit"
                    onClick={() => onClickUpdateMusic(music)}
                  />
                </Can>
                <Can permissions={["music.delete"]}>
                  <IconButton
                    iconName="delete"
                    onClick={() => onClickDeleteMusic(music)}
                  />
                </Can>
              </Row>
            ),
          }))}
        />
      )}
      <CreateMusicFormModal
        participants={participants || []}
        ref={createMusicFormModalRef}
        automaticPlatforms={automaticPlatforms || []}
        onRequestClose={() => createMusicFormModalRef?.current?.hide()}
        onSubmit={onCreateMusic}
      />
      <UpdateMusicFormModal
        participants={participants || []}
        automaticPlatforms={automaticPlatforms || []}
        ref={updateMusicFormModalRef}
        onRequestClose={() => updateMusicFormModalRef?.current?.hide()}
        onSubmit={onUpdateMusic}
      />
      <Alert ref={alertRef} />
    </DefaultLayout>
  );
}
