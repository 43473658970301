import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getAll = useCallback(() => {
    // const params: any = [];
    // searchParams.forEach((value, key) => {
    //   params[key] = value;
    // });
    // return params;
    const params: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);

  const add = useCallback(
    (key: string, value: any) => {
      const params = getAll();
      setSearchParams(
        `?${new URLSearchParams({ ...params, [key]: `${value}` })}`
      );
    },
    [setSearchParams, getAll]
  );

  const addMany = useCallback(
    (values: { [key: string]: any }) => {
      const params = getAll();
      setSearchParams(`?${new URLSearchParams({ ...params, ...values })}`);
    },
    [setSearchParams, getAll]
  );

  const remove = useCallback(
    (key: string) => {
      const params = getAll();
      delete params[key];
      setSearchParams(`?${new URLSearchParams({ ...params })}`);
    },
    [setSearchParams, getAll]
  );

  function get(key: string) {
    return searchParams.get(key);
  }

  return { add, get, addMany, getAll, remove };
}
