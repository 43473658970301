import { type FontWeightProps } from '../../../types/theme'

export const fontWeights: FontWeightProps = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}
