declare global {
  interface Window {
    _env_: { REACT_APP_API_URL: string };
  }
}

// export const API_URL = window._env_.REACT_APP_API_URL;
// export const API_URL = "http://localhost:3000"; // LOCAL
// export const API_URL = "https://api-hit-maker-stg.apps.tsuru.dev.gcp.i.globo"; // STG
export const API_URL = "https://api-estrela-hit-maker.g.globo/"; // PROD

// IN SECONDS
export const ONE_MINUTE_IN_SECONDS = 60;
export const FIFTEEN_MINUTES_IN_SECONDS = ONE_MINUTE_IN_SECONDS * 15;
export const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS * 60;
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24;
export const FIVE_DAYS_IN_SECONDS = ONE_DAY_IN_SECONDS * 5;
export const ONE_YEARS_IN_SECONDS = ONE_DAY_IN_SECONDS * 365;

// MILISECONDS
export const ONE_SECOND_IN_MILLISECONDS = 1000;
export const THIRTY_SECONDS_IN_MILLISECONDS = ONE_SECOND_IN_MILLISECONDS * 30;
export const ONE_MINUTE_IN_MILLISECONDS = ONE_SECOND_IN_MILLISECONDS * 60;
export const THREE_MINUTES_IN_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * 3;
export const FIVE_MINUTES_IN_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * 5;
export const ONE_HOUR_IN_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * 60;
export const TWENTY_FOUR_HOURS_IN_MILLISECONDS = ONE_HOUR_IN_MILLISECONDS * 24;
