import { useMutation } from "react-query";
import { api } from "../api";
import { UsersKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const deleteUserRequest = async (params: {
  userId: string;
}): Promise<void> => {
  await api.delete("api/user", { params });
};

export function useDeleteUser() {
  return useMutation(deleteUserRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
