import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]};
  min-width: 220px;
`;

export const Wrapper = styled.select`
  height: 48px;
  background-color: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.onSurfaceSecondary};
  border-radius: ${({ theme }) => theme.radii.md};
  padding: 0px ${({ theme }) => theme.spaces[4]};
  font-size: ${({ theme }) => theme.fontSizes.text_lg};
  outline: none;
  color: ${({ theme }) => theme.colors.onSurfacePrimary};

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Option = styled.option``;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.onSurfacePrimary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
