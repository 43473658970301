import { Link } from "react-router-dom";
import { Headline, IconButton, Spinner } from "../../atoms";
import { SideBar } from "../../organisms";
import {
  Container,
  Content,
  Wrapper,
  WrapperHeadline,
  WrapperSpinner,
} from "./default-layout.styles";
import { DefaultLayoutProps } from "./default-layout.types";
import { Header } from "../../molecules/header";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useMediaQuery } from "../../../hooks/use-media-query";

export function DefaultLayout(props: DefaultLayoutProps) {
  const [showSideBar, setShowSideBar] = useState(true);
  const { breakpoints } = useTheme();
  const matches = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  function toogleMenu() {
    setShowSideBar((prevState) => !prevState);
  }

  useEffect(() => {
    setShowSideBar(matches);
  }, [matches]);

  return (
    <Container>
      <Header onClickMenu={toogleMenu} />
      <Wrapper>
        <SideBar isOpen={showSideBar} />
        <Content>
          {props.isLoading ? (
            <WrapperSpinner>
              <Spinner size="82px" />
            </WrapperSpinner>
          ) : (
            <>
              {props.goBackHref && (
                <Link to={props.goBackHref}>
                  <IconButton iconName="back" />
                </Link>
              )}
              <WrapperHeadline>
                {props.headline && <Headline {...props.headline} />}
                {props.headlineRightComponent}
              </WrapperHeadline>
              {props.children}
            </>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
}
