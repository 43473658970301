import { IconNameProps } from "../../components";
import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { Log, LogType } from "../../types/log";
import { LogsKeys as Keys } from "./state-keys";

type ResponseProps = {
  pages: number;
  data: Array<Omit<Log, "typeColor" | "typeIconName">>;
};

type Output = {
  pages: number;
  data: Log[];
};

type ParamsProps = {
  page: number;
  starts_at?: string;
  ends_at?: string;
  search?: string;
  username?: string;
};

type TypeAdapterProps = {
  [key in LogType]: { iconName: IconNameProps; color: string };
};

export const logTypeAdapter: TypeAdapterProps = {
  error: { iconName: "close", color: "#ff8094" },
  info: { iconName: "info", color: "#8acef4" },
  warn: { iconName: "warning", color: "#FDD92D" },
};

const processor = (response: ResponseProps): Output => {
  const data = response.data.map((item) => ({
    ...item,
    typeColor: logTypeAdapter[item.type].color,
    typeIconName: logTypeAdapter[item.type].iconName,
  }));
  return { data, pages: response.pages };
};

export const useListLogs = (props?: UseFetchHooks<Output, ParamsProps>) => {
  const { context, params, config } = props || {};
  return useFetch<Output>({
    key: Keys.list(
      params?.page!,
      params?.search,
      params?.username,
      params?.starts_at,
      params?.ends_at
    ),
    url: "api/log",
    params,
    context,
    config,
    processor,
  });
};
