import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { fetcher } from "../services/fetcher";
import { ResponseType } from "axios";

export type UseFetchProps<T, P = any> = {
  key: string | QueryKey;
  url: string;
  params?: P;
  config?:
    | Omit<UseQueryOptions<any, Error, T, QueryKey>, "queryKey" | "queryFn">
    | undefined;
  context?: any;
  processor?: (data: any) => any;
  responseType?: ResponseType;
};

export type UseFetchHooks<T, P = any> = Omit<
  UseFetchProps<T, P>,
  "key" | "url"
>;

export const useFetch = <T>({
  key,
  url,
  params,
  config,
  context,
  processor,
  responseType,
}: UseFetchProps<T>): UseQueryResult<T, Error> => {
  return useQuery(
    key,
    () => fetcher({ url, params, context, processor, responseType }),
    {
      ...config,
    }
  );
};
