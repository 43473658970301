import styled from "styled-components";

export const Container = styled.table`
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
  border: 1px solid ${({ theme }) => theme.colors.surface};
`;

export const WrapperHeader = styled.tr`
  background-color: red;
`;

export const Header = styled.th`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: start;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: ${({ theme }) => theme.spaces[4]};
`;

export const WrapperLine = styled.tr``;

export const Line = styled.td`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface};
`;
