import {} from "react-icons/md";
import { IconNameProps, IconProps } from "./icon.types";
import {
  MdPerson,
  MdBarChart,
  MdMusicNote,
  MdLibraryMusic,
  MdDelete,
  MdEdit,
  MdClose,
  MdArrowBack,
  MdCheck,
  MdManageSearch,
  MdChevronRight,
  MdOutlineMoreVert,
  MdAdd,
  MdChecklist,
  MdInfo,
  MdWarning,
  MdError,
  MdChevronLeft,
  MdFilterAlt,
  MdMenu,
  MdExitToApp,
  MdShowChart,
} from "react-icons/md";
import { ReactNode } from "react";

export function Icon(props: IconProps) {
  const mapper: { [key in IconNameProps]: ReactNode } = {
    user: <MdPerson {...props} />,
    "line-chart": <MdShowChart {...props} />,
    "bar-chart": <MdBarChart {...props} />,
    "pen-ruler": <MdPerson {...props} />,
    music: <MdMusicNote {...props} />,
    people: <MdPerson {...props} />,
    "library-music": <MdLibraryMusic {...props} />,
    delete: <MdDelete {...props} />,
    edit: <MdEdit {...props} />,
    close: <MdClose {...props} />,
    back: <MdArrowBack {...props} />,
    check: <MdCheck {...props} />,
    "manage-search": <MdManageSearch {...props} />,
    "chevron-right": <MdChevronRight {...props} />,
    "outline-more": <MdOutlineMoreVert {...props} />,
    add: <MdAdd {...props} />,
    "check-list": <MdChecklist {...props} />,
    info: <MdInfo {...props} />,
    warning: <MdWarning {...props} />,
    error: <MdError {...props} />,
    "chevron-left": <MdChevronLeft {...props} />,
    filter: <MdFilterAlt {...props} />,
    menu: <MdMenu {...props} />,
    exit: <MdExitToApp {...props} />,
  };

  return <>{mapper[props.name]}</>;
}
