import { type RadiiProps } from '../../../types/theme'

export const radii: RadiiProps = {
  xs: '4px',
  sm: '6px',
  md: '8px',
  lg: '12px',
  xl: '15px',
  round: '9999px',
}
