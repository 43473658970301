import { useMutation } from "react-query";
import { api } from "../api";
import { UsersKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";
import { User } from "../../types/user";

export const updateUserRequest = async (
  body: Omit<User, "permissions">
): Promise<void> => {
  await api.put("api/user", body);
};

export function useUpdateUser() {
  return useMutation(updateUserRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
