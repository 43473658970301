import { AuthKeys as Keys } from "./state-keys";
import { User } from "../../types/user";
import { UseFetchHooks, useFetch } from "../../hooks/use-fetch";

const processor = (data: { user: User }): User => {
  return data.user;
};

export const useGetSession = (props?: UseFetchHooks<User>) => {
  const { context, params, config } = props || {};
  return useFetch<User>({
    key: Keys.me(),
    url: "api/auth/me",
    params,
    context,
    config,
    processor,
  });
};
