import { Ref, forwardRef } from "react";
import { Modal } from "../../../../components";
import {
  CreateCalculationPeriodFormModalProps,
  CreateCalculationPeriodFormModalHandle,
} from "./create-calculation-period-form-modal.types";
import { CalculationPeriodForm } from "../../components/calculation-period-form/calculation-period-form";

function Component(
  props: CreateCalculationPeriodFormModalProps,
  ref: Ref<CreateCalculationPeriodFormModalHandle>
): JSX.Element {
  return (
    <Modal size="lg" headline={{ title: "Adicionar apuração" }} ref={ref}>
      <CalculationPeriodForm {...props} submitButtonText="Adicionar" />
    </Modal>
  );
}

export const CreateCalculationPeriodFormModal = forwardRef<
  CreateCalculationPeriodFormModalHandle,
  CreateCalculationPeriodFormModalProps
>(Component);
