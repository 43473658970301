import { Icon } from "../icon";
import { Spinner } from "../spinner";
import { Container } from "./button.styles";
import { ButtonProps } from "./button.types";

export function Button({
  variant = "solid",
  children,
  isLoading,
  danger,
  leftIconName,
  ...props
}: ButtonProps) {
  return (
    <Container
      $variant={variant}
      $danger={danger}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <Spinner size="18px" />
      ) : (
        <>
          {leftIconName && <Icon name={leftIconName} />}
          {children}
        </>
      )}
    </Container>
  );
}
