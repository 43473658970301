export const AnalyticsKeys = {
  all: ["analytics"] as const,
  historic: (calculationPeriodId: string, platformId: string, musics: string) =>
    [
      ...AnalyticsKeys.all,
      "historic",
      calculationPeriodId,
      platformId,
      musics,
    ] as const,
};
