import styled from "styled-components";
import { TextInput } from "../../../../components";

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[7]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[7]};
`;

export const RowAutomaticPlatforms = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spaces[4]};
`;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]};
  padding-top: ${({ theme }) => theme.spaces[6]};
  justify-content: end;
`;

export const DatesRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizes[6]};
  width: 100%;
`;

export const DateInput = styled(TextInput)``;
