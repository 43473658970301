import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
  box-sizing: border-box;
`;

export const Wrapper = styled.main`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  padding: ${({ theme }) => theme.spaces[6]};
  flex-direction: column;
  max-width: 1240px;
  margin: 0px auto;
  gap: ${({ theme }) => theme.spaces[7]};
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const WrapperSpinner = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const WrapperHeadline = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spaces[8]};
  }
`;
