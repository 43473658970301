import { CalculationPeriodStatus } from "../../../types/calculation-period";

type StatusAdapterProps = {
  [key in CalculationPeriodStatus]: { title: string; color: string };
};

export const statusAdapter: StatusAdapterProps = {
  activated: { title: "Ativada", color: "#FDD92D" },
  closed: { title: "Encerrada", color: "#DC93A2" },
  created: { title: "Criada", color: "#b2bfca" },
};
