import { Icon } from "../../atoms";
import styled from "styled-components";

export const Container = styled.button`
  border: none;
  padding: ${({ theme }) => theme.spaces[4]};
  padding-left: 0;
  gap: ${({ theme }) => theme.spaces[4]};
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[1]};
`;

export const CheckIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.onPrimaryPrimary,
}))<{ $checked?: boolean }>`
  visibility: ${({ $checked }) => ($checked ? "visible" : "hidden")};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
  overflow: hidden;
  white-space: nowrap;
`;

export const Text = styled.label`
  align-self: flex-start;
  text-align: start;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.text_md};
  color: ${({ theme }) => theme.colors.onSurfacePrimary};
  cursor: pointer;
`;

export const Description = styled.span`
  align-self: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.text_sm};
  color: ${({ theme }) => theme.colors.onSurfaceSecondary};
`;

export const StyledCheckbox = styled.label<{ $checked?: boolean }>`
  width: ${({ theme }) => theme.sizes[7]};
  height: ${({ theme }) => theme.sizes[7]};
  margin-right: ${({ theme }) => theme.spaces[4]};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $checked }) =>
    $checked ? theme.colors.primary : theme.colors.onSurfaceDisabled};
`;
