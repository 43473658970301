import { QueryClient } from "react-query";
import { DEFAULT_STALE_TIME, QUERY_RETRY_ATTEMPTS } from "../configs/queries";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: QUERY_RETRY_ATTEMPTS,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
});
