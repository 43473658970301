import { useEffect } from "react";
import {
  DefaultLayout,
  IconButton,
  Label,
  List,
  Select,
} from "../../components";
import { useQueryParams } from "../../hooks/use-query-params";
import { useListCalculationPeriods } from "../../services/calculation-periods/use-list-calculation-periods";
import { useGetDashboardResume } from "../../services/dashboard/use-get-dashboard-resume";
import { useNavigate } from "react-router-dom";

export function DashboardPage() {
  const { add, get } = useQueryParams();
  const navigate = useNavigate();
  const calculationPeriodId = get("id");

  const { data: calculationPeriods } = useListCalculationPeriods();

  const { data: dashboard } = useGetDashboardResume({
    params: { calculationPeriodId: calculationPeriodId! },
    config: { enabled: !!calculationPeriodId },
  });

  useEffect(() => {
    function setDefaultCalculationPeriod() {
      const activatedCalculationPeriod = calculationPeriods?.find(
        (item) => item.status === "activated"
      );
      if (activatedCalculationPeriod) {
        add("id", activatedCalculationPeriod.id);
      } else if (calculationPeriods?.length) {
        add("id", calculationPeriods[0].id);
      }
    }
    setDefaultCalculationPeriod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationPeriods]);

  function onClickDetails(musicId: string) {
    const url = `/dashboard/detail?calculationPeriodId=${calculationPeriodId}&musicId=${musicId}`;
    navigate(url);
  }

  const selectOptions =
    calculationPeriods?.map((item) => ({
      title: item.name,
      value: item.id,
    })) || [];

  return (
    <DefaultLayout
      headline={{
        title: "Dashboard",
        description: "Ranking de músicas na apuração",
      }}
      headlineRightComponent={
        <>
          {calculationPeriodId && selectOptions?.length && (
            <Select
              label="Apuração"
              defaultValue={calculationPeriodId || undefined}
              onChange={(evt) => add("id", evt.target.value)}
              options={selectOptions}
            />
          )}
        </>
      }
    >
      {dashboard?.length && (
        <List
          data={dashboard.map((item, index) => ({
            title: item.participant?.name,
            description: item?.musicName,
            badge: { text: `${item?.total?.toLocaleString()} Views` },
            avatar: { name: item?.participant?.profile?.name },
            leftContent: <Label>{`${index + 1}º`}</Label>,
            rightContent: (
              <IconButton
                onClick={() => onClickDetails(item?.musicId)}
                iconName="chevron-right"
                variant="solid"
              />
            ),
          }))}
        />
      )}
    </DefaultLayout>
  );
}
