import { Ref, forwardRef } from "react";
import { Modal } from "../../../../components";
import {
  CreateMusicFormModalProps,
  CreateMusicFormModalHandle,
} from "./create-music-form-modal.types";
import { MusicForm } from "../../components/music-form/music-form";

function Component(
  props: CreateMusicFormModalProps,
  ref: Ref<CreateMusicFormModalHandle>
): JSX.Element {
  return (
    <Modal headline={{ title: "Adicionar música" }} ref={ref}>
      <MusicForm {...props} />
    </Modal>
  );
}

export const CreateMusicFormModal = forwardRef<
  CreateMusicFormModalHandle,
  CreateMusicFormModalProps
>(Component);
