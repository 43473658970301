import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spaces[6]};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const Item = styled.div``;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]};
  padding-top: ${({ theme }) => theme.spaces[6]};
  justify-content: end;
`;

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[8]};
`;
