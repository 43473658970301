import { type LineHeightProps } from '../../../types/theme'

export const lineHeights: LineHeightProps = {
  0: 10,
  1: 16,
  2: 20,
  3: 24,
  4: 28,
  5: 32,
  6: 36,
  7: 40,
  8: 48,
  9: 60,
  10: 72,
  11: 96,
}
