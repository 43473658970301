import { SectionHeader } from "../../atoms/section-header";
import { ListItem } from "../../molecules";
import { Container, ContainerData } from "./section-list.styles";
import { SectionListProps } from "./section-list.types";

export function SectionList(props: SectionListProps) {
  return (
    <Container>
      {props?.sections?.map((section, sectionIndex) => (
        <div key={`${section.title}${sectionIndex}`}>
          {section.title && <SectionHeader text={section.title} />}
          <ContainerData>
            {section.data?.map((item, itemIndex) => (
              <ListItem key={`${item.title}${itemIndex}`} {...item} />
            ))}
          </ContainerData>
        </div>
      ))}
    </Container>
  );
}
