import { useState } from "react";
import {
  Label,
  SelectionList,
  Button,
  SelectionListOption,
} from "../../../../../components";
import {
  Content,
  SelectionListWrapper,
  Footer,
} from "../calculation-period-form.styles";

import { PlatformsBlockProps } from "./platforms-block.types";
import { AlertContent } from "../../../../../components/molecules/alert-content";

export function PlatformsBlock(props: PlatformsBlockProps) {
  const [error, setError] = useState<string>();
  const [platformsIds, setPlatformsIds] = useState<string[]>(
    props.defaultValues?.platforms || []
  );

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    if (platformsIds.length === 0) {
      setError("Selecione pelo menos uma plataforma.");
    } else {
      await props.onSubmit({ platforms: platformsIds });
    }
  }

  const platformsOptions: SelectionListOption[] = props.platforms.map(
    (platform) => ({
      title: platform.name,
      value: platform.id,
      avatar: { name: platform.photo?.name },
      description: platform.typeTitle,
    })
  );

  return (
    <Content onSubmit={onSubmit}>
      <Label>Plataformas</Label>
      <SelectionListWrapper>
        <SelectionList
          numberOfColumns={3}
          defaultValues={platformsIds}
          options={platformsOptions}
          onChange={setPlatformsIds}
        />
      </SelectionListWrapper>
      {error && <AlertContent description={error} />}
      <Footer>
        <Button type="button" variant="ghost" onClick={props.onPressCancel}>
          Voltar
        </Button>
        <Button isLoading={props.isLoading} type="submit">
          Continuar
        </Button>
      </Footer>
    </Content>
  );
}
