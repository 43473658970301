import { Badge, PrivateAvatar } from "../../atoms";
import {
  HiddenCheckbox,
  Container,
  Text,
  StyledCheckbox,
  CheckIcon,
  Content,
  Description,
} from "./select-item.styles";
import { SelectItemProps } from "./select-item.types";

export function SelectItem({
  title,
  value,
  onChange,
  checked,
  description,
  avatar,
  badge,
}: SelectItemProps): JSX.Element {
  function handleOnChange() {
    onChange(!checked);
  }

  return (
    <Container
      type="button"
      id={value}
      onClick={handleOnChange}
      role="checkbox"
    >
      <HiddenCheckbox
        data-testid={value}
        name={value}
        aria-checked={checked}
        checked={checked}
        id={value}
        onChange={handleOnChange}
      />
      <StyledCheckbox $checked={checked}>
        <CheckIcon name="check" $checked={checked} />
      </StyledCheckbox>
      {avatar && <PrivateAvatar {...avatar} />}
      <Content>
        <Text>{title}</Text>
        <Description>{description}</Description>
        {badge && <Badge {...badge} />}
      </Content>
    </Container>
  );
}
