import {
  CalculationPeriodFormProps,
  CalculationPeriodFormSubmitData,
} from "./calculation-period-form.types";
import { Container } from "./calculation-period-form.styles";
import { useRef, useState } from "react";
import { Steper, SteperHandle } from "../../../../components/organisms/steper";
import { GeralInfoBlock } from "./geral-info-block/geral-info-block";
import { PlatformsBlock } from "./platforms-block/platforms-block";
import { MusicsBlock } from "./musics-block/musics-block";
import { GeralInfoBlockData } from "./geral-info-block/geral-info-block.types";
import { PlatformsBlockData } from "./platforms-block/platforms-block.types";
import { MusicsBlockData } from "./musics-block/musics-block.types";
import { useToast } from "../../../../hooks/use-toast";

export function CalculationPeriodForm(props: CalculationPeriodFormProps) {
  const steperRef = useRef<SteperHandle>(null);
  const { errorHandler } = useToast();
  const [values, setValues] = useState<
    Partial<CalculationPeriodFormSubmitData>
  >(props.defaultValues || {});
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(data: CalculationPeriodFormSubmitData) {
    try {
      setIsLoading(true);
      await props.onSubmit(data);
      props.onRequestClose();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmitGeralInfo(data: GeralInfoBlockData) {
    setValues({ ...values, ...data });
    next();
  }

  async function onSubmitPlatforms(data: PlatformsBlockData) {
    setValues({ ...values, ...data });
    next();
  }

  async function onSubmitMusics(data: MusicsBlockData) {
    setValues({ ...values, ...data });
    await onSubmit({ ...values, ...data } as CalculationPeriodFormSubmitData);
  }

  function next() {
    steperRef?.current?.right();
  }

  function goBack() {
    steperRef.current?.left();
  }

  return (
    <Container>
      <Steper
        ref={steperRef}
        steps={[
          {
            title: "Informações gerais",
            content: (
              <GeralInfoBlock
                onPressCancel={props.onRequestClose}
                onSubmit={onSubmitGeralInfo}
                isLoading={isLoading}
                defaultValues={values as GeralInfoBlockData}
              />
            ),
          },
          {
            title: "Seleção de plataformas",
            content: (
              <PlatformsBlock
                platforms={props.platforms}
                onPressCancel={goBack}
                isLoading={isLoading}
                onSubmit={onSubmitPlatforms}
                defaultValues={values as PlatformsBlockData}
              />
            ),
          },
          {
            title: "Seleção de músicas",
            content: (
              <MusicsBlock
                musics={props.musics}
                onPressCancel={goBack}
                defaultValues={values as MusicsBlockData}
                isLoading={isLoading}
                onSubmit={onSubmitMusics}
              />
            ),
          },
        ]}
      />
    </Container>
  );
}
