import styled, { keyframes } from "styled-components";

interface SpinnerProps {
  size?: string;
  borderWidth?: string;
  borderColor?: string;
  duration?: number;
}

const spinnerAnimation = keyframes`
  from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
  `;

export const Container = styled.div<SpinnerProps>`
  height: ${(p) => (p.size ? p.size : "3rem")};
  width: ${(p) => (p.size ? p.size : "3rem")};
  border: ${(p) => (p.borderWidth ? p.borderWidth : "4px")} solid #000;
  border-top-color: ${({ borderColor, theme }) =>
    borderColor ? borderColor : theme.colors.primary};
  border-radius: 50%;
  animation: ${spinnerAnimation}
    ${(p) => (p.duration ? `${p.duration}ms` : "800ms")} linear infinite;
`;
