import { DefaultLayout, Label, List } from "../../../components";

import { Views, WrapperViews } from "./calculation-period-detail.styles";
import { useGetDashboardPlatforms } from "../../../services/dashboard/use-get-dashboard-platforms";
import { useQueryParams } from "../../../hooks/use-query-params";

export function DashboardDetailPage() {
  const { get } = useQueryParams();
  const calculationPeriodId = get("calculationPeriodId");
  const musicId = get("musicId");

  const { data } = useGetDashboardPlatforms({
    params: { calculationPeriodId: calculationPeriodId!, musicId: musicId! },
    config: { enabled: !!calculationPeriodId && !!musicId },
  });

  return (
    <DefaultLayout
      goBackHref="/dashboard"
      headline={{
        title: data?.music.name || "",
        description: "Detalhamento de visualizações por plataforma",
      }}
    >
      {data?.platforms?.length && (
        <List
          data={data.platforms.map((platform) => ({
            title: platform.name,
            avatar: { name: platform.photo?.name },
            badge: { text: platform.typeTitle, bgColor: platform.typeColor },
            rightContent: (
              <WrapperViews>
                <Label>Visualizações:</Label>
                <Views>{platform.views?.toLocaleString()}</Views>
              </WrapperViews>
            ),
          }))}
        />
      )}
    </DefaultLayout>
  );
}
