import { IconButton } from "../../atoms";
import { Container, Content } from "./pagination.styles";
import { PaginationProps } from "./pagination.types";

export function Pagination(props: PaginationProps) {
  const isDisabledLeft = props.index <= 0;
  const isDisabledRight = props.index >= props.pages - 1;

  function onChangeLeft() {
    props.onChange(props.index - 1);
  }

  function onChangeRight() {
    props.onChange(props.index + 1);
  }

  return (
    <Container>
      <IconButton
        disabled={isDisabledLeft}
        onClick={onChangeLeft}
        iconName="chevron-left"
      />
      <Content>{`${props.index + 1} de ${props.pages}`}</Content>
      <IconButton
        disabled={isDisabledRight}
        onClick={onChangeRight}
        iconName="chevron-right"
      />
    </Container>
  );
}
