import styled from "styled-components";

export const Container = styled.button<{ $selected?: boolean }>`
  padding: ${({ theme }) => `${theme.spaces[4]} ${theme.spaces[6]}`};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.onSurfacePrimary : theme.colors.surface};
  border: none;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii.md};
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.surface : theme.colors.onSurfacePrimary};

  &:hover {
    opacity: 0.8;
  }
`;
