import { SelectItem } from "../../molecules";
import { Container, Search, Wrapper } from "./selection-list.styles";
import { useState } from "react";
import { SelectionListProps } from "./selection-list.types";
import { useSearch } from "../../../hooks/use-search";

export function SelectionList({
  options,
  onChange,
  defaultValues,
  numberOfColumns = 1,
  showSearch = false,
}: SelectionListProps): JSX.Element {
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState<string[]>(
    defaultValues || []
  );

  const { filteredData } = useSearch({
    data: options,
    search,
    searchableKeys: ["title", "description"],
  });

  function handleChange(value: string, checked: boolean) {
    if (checked) {
      setSelectedItems((prevState) => [...prevState, value]);
      onChange([...selectedItems, value]);
    } else {
      setSelectedItems((prevState) =>
        prevState.filter((item) => item !== value)
      );
      onChange(selectedItems.filter((item) => item !== value));
    }
  }

  return (
    <Wrapper>
      <Search
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        placeholder="Pesquisar"
      />

      <Container $numberOfColumns={numberOfColumns}>
        {filteredData.map((option) => {
          return (
            <SelectItem
              {...option}
              key={option.value}
              onChange={(checked) => handleChange(option.value, checked)}
              checked={!!selectedItems.find((value) => option.value === value)}
            />
          );
        })}
      </Container>
    </Wrapper>
  );
}
