export const LogsKeys = {
  all: ["logs"] as const,
  list: (
    page: number,
    search?: string,
    username?: string,
    starts_at?: string,
    ends_at?: string
  ) =>
    [
      ...LogsKeys.all,
      "list",
      page,
      search,
      username,
      starts_at,
      ends_at,
    ] as const,
};
