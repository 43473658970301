import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { FilesKeys as Keys } from "./state-keys";

const processor = (data: Blob): { src: string } => {
  const url = URL.createObjectURL(data);
  return { src: url };
};

export const useDownloadFile = (
  props?: UseFetchHooks<{ src: string }, { filename: string }>
) => {
  const { context, params, config } = props || {};
  return useFetch<{ src: string }>({
    key: Keys.get(props?.params?.filename!),
    url: "api/file",
    params,
    context,
    config,
    processor,
    responseType: "blob",
  });
};
