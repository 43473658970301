import axios, { AxiosInstance } from "axios";
import { API_URL } from "../../configs/constants";
import { addTokenInterceptor } from "./interceptors/token-interceptor";

export function createApi(_ = undefined): AxiosInstance {
  return axios.create({
    baseURL: `${API_URL}`,
    // withCredentials: true,
    // headers: {
    //   "Content-Type": "application/json",
    //   accept: "application/json",
    //   "Access-Control-Allow-Origin": "*",
    // },
  });
}

export function setupApiClient(ctx = undefined): AxiosInstance {
  const apiInstance = createApi(ctx);

  addTokenInterceptor(apiInstance);
  // Add interceptors here if necessary
  return apiInstance;
}

export const api = setupApiClient();
