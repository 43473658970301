import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal, ModalHandle } from "../../../../components";
import {
  UpdatePlatformFormModalHandle,
  UpdatePlatformFormModalProps,
} from "./update-platform-form-modal.types";
import { PlatformForm } from "../../components/platform-form/platform-form";
import {
  PlatformFormDefaultValues,
  PlatformFormSubmitData,
} from "../../components/platform-form/platform-form.types";

function Component(
  props: UpdatePlatformFormModalProps,
  ref: Ref<UpdatePlatformFormModalHandle>
): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [defaultValues, setDefaultValues] =
    useState<PlatformFormDefaultValues>();

  function show(data: PlatformFormDefaultValues) {
    setDefaultValues(data);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  async function onSubmit(data: PlatformFormSubmitData) {
    return props.onSubmit({ ...data, platformId: defaultValues?.id! });
  }

  return (
    <Modal headline={{ title: "Editar plataforma" }} ref={modalRef}>
      <PlatformForm
        {...props}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        fileRequired={false}
      />
    </Modal>
  );
}

export const UpdatePlatformFormModal = forwardRef<
  UpdatePlatformFormModalHandle,
  UpdatePlatformFormModalProps
>(Component);
