import { Ref, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Container, Slide } from './steper.styles'
import { SteperHandle, SteperProps } from './steper.types'
import { SteperIndicators } from '../../molecules'
import { AnimatePresence } from 'framer-motion'

function Component({ steps }: SteperProps, ref: Ref<SteperHandle>): JSX.Element {
  const [index, setIndex] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    left,
    right,
  }))

  function left() {
    if (index > 0) {
      setIndex(index - 1)
    }
  }

  function right() {
    if (index < steps.length - 1) {
      setIndex(index + 1)
    }
  }

  return (
    <Container ref={containerRef}>
      <SteperIndicators index={index} steps={steps} />

      <AnimatePresence>
        <Slide>{steps[index]?.content}</Slide>
      </AnimatePresence>
    </Container>
  )
}

export const Steper = forwardRef<SteperHandle, SteperProps>(Component)
