import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { CalculationPeriod } from "../../types/calculation-period";
import { statusAdapter } from "./helpers/status-adapter";
import { CalculationPeriodsKeys as Keys } from "./state-keys";

const processor = (data: CalculationPeriod[]): CalculationPeriod[] => {
  return data?.map((item) => ({
    ...item,
    startsAt: new Date(item.startsAt),
    endsAt: new Date(item.endsAt),
    statusColor: statusAdapter[item.status].color,
    statusTitle: statusAdapter[item.status].title,
  }));
};

export const useListCalculationPeriods = (
  props?: UseFetchHooks<CalculationPeriod[]>
) => {
  const { context, params, config } = props || {};
  return useFetch<CalculationPeriod[]>({
    key: Keys.list(),
    url: "api/calculation-period",
    params,
    context,
    config,
    processor,
  });
};
