import { useMutation } from "react-query";
import { api } from "../api";
import { ParticipantsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const createParticipantRequest = async (body: {
  name: string;
  file: File;
}): Promise<void> => {
  const formData = new FormData();
  formData.append("name", body.name);
  formData.append("file", body.file);
  await api.post("api/participant", formData);
};

export function useCreateParticipant() {
  return useMutation(createParticipantRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
