import { Container } from "./tab-item.styles";
import { TabItemProps } from "./tab-item.types";

export function TabItem({ selected, text, ...props }: TabItemProps) {
  return (
    <Container $selected={selected} {...props}>
      {text}
    </Container>
  );
}
