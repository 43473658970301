import { IconNameProps } from "../../atoms";

export const sideBarData: SideBarDataProps[] = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: "bar-chart",
    permissions: ["dashboard.resume"],
  },
  {
    title: "Análises",
    href: "/analytics",
    icon: "line-chart",
    permissions: ["analytics.historic"],
  },
  {
    title: "Participantes",
    href: "/participants",
    icon: "pen-ruler",
    permissions: ["participant.list"],
  },
  {
    title: "Plataformas",
    href: "/platforms",
    icon: "library-music",
    permissions: ["platform.list"],
  },
  {
    title: "Músicas",
    href: "/musics",
    icon: "music",
    permissions: ["music.list"],
  },
  {
    title: "Apuração",
    href: "/calculation-periods",
    icon: "check-list",
    permissions: ["calculation-period.list"],
  },
  {
    title: "Usuários",
    href: "/users",
    icon: "user",
    permissions: ["user.list"],
  },
  {
    title: "Logs",
    href: "/logs",
    icon: "manage-search",
    permissions: ["log.list"],
  },
];

type SideBarDataProps = {
  title: string;
  icon: IconNameProps;
  href: string;
  permissions: string[];
};
