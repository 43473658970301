import { useState } from "react";
import { useSearch } from "../../../hooks/use-search";
import { ListItem } from "../../molecules";
import { Container, Search, WrapperList } from "./list.styles";
import { ListProps } from "./list.types";

export function List({ showSearch = true, ...props }: ListProps) {
  const [search, setSearch] = useState("");

  const { filteredData } = useSearch({
    data: props.data,
    search,
    searchableKeys: ["title", "description"],
  });

  return (
    <WrapperList>
      {showSearch && (
        <Search
          placeholder="Pesquisar"
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
      )}
      <Container>
        {filteredData?.map((item, index) => (
          <ListItem key={`${item.title}${index}`} {...item} />
        ))}
      </Container>
    </WrapperList>
  );
}
