import { ResponsiveLine } from "@nivo/line";
import React from "react";
import { Container } from "./line-chart.styles";
import { LineChartProps } from "./line-chart.types";
import { useTheme } from "styled-components";

export function LineChart(props: LineChartProps): React.JSX.Element {
  const { colors } = useTheme();

  return (
    <Container>
      <ResponsiveLine
        data={props.data}
        enableArea
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        theme={{
          grid: { line: { stroke: colors.surface } },
          axis: { ticks: { text: { fill: colors.onBackgroundPrimary } } },
          legends: { text: { fill: colors.onBackgroundPrimary } },
        }}
        // enableGridX={false}
        // enableGridY={false}
        // yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          renderTick: (value) => {
            console.log(value);
            return <></>;
          },
          // tickSize: 5,
          // tickPadding: 5,
          // tickRotation: 90,
          // legend: "transportation",
          // legendOffset: 36,
          // legendPosition: "middle",
          // truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
          truncateTickAt: 0,
        }}
        colors={{ scheme: "nivo" }}
        pointSize={2}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        // enableTouchCrosshair={true}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );

  // return (
  //   <Container>
  //     <ResponsiveLine
  //       data={props.data}
  //       margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
  //       xScale={{ type: "point" }}
  //       enableArea
  //       isInteractive
  //       // onClick={props.onClick}
  //       yScale={{
  //         type: "linear",
  //         // min: 'auto',
  //         // max: 'auto',
  //         // stacked: true,
  //         reverse: false,
  //       }}
  //       // yFormat=" >-.2f"
  //       axisTop={null}
  //       axisRight={null}
  //       enableGridX={false}

  //       // tooltip={(item, ola) => {
  //       //   console.log(item.point., ola);
  //       //   return (
  //       //     <div style={{ height: 50, width: 200, background: "red" }}></div>
  //       //   );
  //       // }}
  //       // axisBottom={{
  //       //   tickSize: 5,
  //       //   tickPadding: 5,
  //       //   tickRotation: 0,
  //       //   legend: 'transportation',
  //       //   legendOffset: 36,
  //       //   legendPosition: 'middle',
  //       //   truncateTickAt: 0,
  //       // }}
  //       // axisLeft={{
  //       //   tickSize: 5,
  //       //   tickPadding: 5,
  //       //   tickRotation: 0,
  //       //   legend: 'count',
  //       //   legendOffset: -40,
  //       //   legendPosition: 'middle',
  //       //   truncateTickAt: 0,
  //       // }}
  //       pointSize={10}
  //       pointColor={{ theme: "background" }}
  //       pointBorderWidth={2}
  //       pointBorderColor={{ from: "serieColor" }}
  //       // pointLabel="data.yFormatted"
  //       pointLabelYOffset={-12}
  //       // enableTouchCrosshair={true}
  //       // useMesh
  //       // debugMesh
  //       // legends={[
  //       //   {
  //       //     anchor: 'bottom-right',
  //       //     direction: 'column',
  //       //     justify: false,
  //       //     translateX: 100,
  //       //     translateY: 0,
  //       //     itemsSpacing: 0,
  //       //     itemDirection: 'left-to-right',
  //       //     itemWidth: 80,
  //       //     itemHeight: 20,
  //       //     itemOpacity: 0.75,
  //       //     symbolSize: 12,
  //       //     symbolShape: 'circle',
  //       //     symbolBorderColor: 'rgba(0, 0, 0, .5)',
  //       //     effects: [
  //       //       {
  //       //         on: 'hover',
  //       //         style: {
  //       //           itemBackground: 'rgba(0, 0, 0, .03)',
  //       //           itemOpacity: 1,
  //       //         },
  //       //       },
  //       //     ],
  //       //   },
  //       // ]}
  //     />
  //   </Container>
  // );
}
