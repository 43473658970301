import { useRef } from "react";
import {
  Alert,
  AlertHandle,
  Button,
  Can,
  DefaultLayout,
  IconButton,
  List,
  Row,
} from "../../components";
import { useListPlatforms } from "../../services/platforms/use-list-platforms";
import { Platform } from "../../types/platform";
import { CreatePlatformFormModal } from "./modal/create-platform-form-modal/create-platform-form-modal";
import { UpdatePlatformFormModal } from "./modal/update-platform-form-modal/update-platform-form-modal";
import { CreatePlatformFormModalHandle } from "./modal/create-platform-form-modal/create-platform-form-modal.types";
import {
  OnUpdatePlatformFormData,
  UpdatePlatformFormModalHandle,
} from "./modal/update-platform-form-modal/update-platform-form-modal.types";
import { useCreatePlatform } from "../../services/platforms/use-create-platform";
import { PlatformFormSubmitData } from "./components/platform-form/platform-form.types";
import { useUpdatePlatform } from "../../services/platforms/use-update-platform";
import { useDeletePlatform } from "../../services/platforms/use-delete-platform";
import { useToast } from "../../hooks/use-toast";

export function PlatformsPage() {
  const alertRef = useRef<AlertHandle>(null);
  const { mutateAsync: createPlatform } = useCreatePlatform();
  const { mutateAsync: updatePlatform } = useUpdatePlatform();
  const { mutateAsync: deletePlatform } = useDeletePlatform();
  const { errorHandler } = useToast();

  const { data: platforms } = useListPlatforms();
  const createPlatformFormModalRef =
    useRef<CreatePlatformFormModalHandle>(null);
  const updatePlatformFormModalRef =
    useRef<UpdatePlatformFormModalHandle>(null);

  function onClickCreatePlatform() {
    createPlatformFormModalRef?.current?.show();
  }

  function onClickUpdatePlatform(platform: Platform) {
    updatePlatformFormModalRef?.current?.show(platform);
  }

  function onClickDeletePlatform(platform: Platform) {
    alertRef?.current?.show({
      title: "Deseja mesmo excluir?",
      description: `Deseja mesmo excluir a plataforma "${platform.name}"?`,
      buttons: [
        {
          children: "Sim, excluir",
          onClick: () => onDeletePlatform(platform),
        },
        { children: "Não", variant: "ghost" },
      ],
    });
  }

  async function onDeletePlatform(platform: Platform) {
    try {
      await deletePlatform({ platformId: platform.id });
    } catch (error) {
      errorHandler(error);
    }
  }

  async function onCreatePlatform(data: PlatformFormSubmitData) {
    await createPlatform(data);
  }

  async function onUpdatePlatform(data: OnUpdatePlatformFormData) {
    await updatePlatform(data);
  }

  return (
    <DefaultLayout
      headlineRightComponent={
        <Can permissions={["platform.create"]}>
          <Button
            leftIconName="add"
            onClick={onClickCreatePlatform}
            variant="outline"
          >
            Adicionar plataforma
          </Button>
        </Can>
      }
      headline={{ title: "Plataformas" }}
    >
      {platforms?.length && (
        <List
          data={platforms?.map((platform) => ({
            avatar: { name: platform.photo?.name },
            title: platform.name,
            badge: { text: platform.typeTitle, bgColor: platform.typeColor },
            rightContent: (
              <Row>
                {platform.type === "manual" && (
                  <>
                    <Can permissions={["platform.update"]}>
                      <IconButton
                        iconName="edit"
                        onClick={() => onClickUpdatePlatform(platform)}
                      />
                    </Can>
                    <Can permissions={["platform.delete"]}>
                      <IconButton
                        iconName="delete"
                        onClick={() => onClickDeletePlatform(platform)}
                      />
                    </Can>
                  </>
                )}
              </Row>
            ),
          }))}
        />
      )}

      <Alert ref={alertRef} />
      <CreatePlatformFormModal
        ref={createPlatformFormModalRef}
        onRequestClose={() => createPlatformFormModalRef?.current?.hide()}
        onSubmit={onCreatePlatform}
      />
      <UpdatePlatformFormModal
        ref={updatePlatformFormModalRef}
        onRequestClose={() => updatePlatformFormModalRef?.current?.hide()}
        onSubmit={onUpdatePlatform}
      />
    </DefaultLayout>
  );
}
