import { useMutation } from "react-query";
import { api } from "../api";
import { MusicsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const createMusicRequest = async (body: {
  name: string;
  participantId: string;
}): Promise<void> => {
  await api.post("api/music", body);
};

export function useCreateMusic() {
  return useMutation(createMusicRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
