import { type ColorProps } from "../../../types/theme";

export const colors: ColorProps = {
  background: "#061323",
  onBackgroundPrimary: "rgba(255,255,255,1)",
  onBackgroundSecondary: "rgba(255,255,255,0.4)",
  onBackgroundDisabled: "rgba(255,255,255,0.1)",

  primary: "#FDD92D",
  onPrimaryPrimary: "#061323",
  onPrimarySecondary: "#061323",
  onPrimaryDisabled: "#061323",

  secondary: "#061323",
  onSecondaryPrimary: "rgba(255,255,255,1)",
  onSecondarySecondary: "rgba(255,255,255,0.4)",
  onSecondaryDisabled: "rgba(255,255,255,0.1)",

  surface: "#0F2135",
  onSurfacePrimary: "rgba(255,255,255,1)",
  onSurfaceSecondary: "rgba(255,255,255,0.4)",
  onSurfaceDisabled: "rgba(255,255,255,0.1)",

  whiteA50: "rgba(255,255,255,0.05)",
  whiteA100: "rgba(255,255,255,0.1)",
  whiteA200: "rgba(255,255,255,0.2)",
  whiteA300: "rgba(255,255,255,0.3)",
  whiteA400: "rgba(255,255,255,0.4)",
  whiteA500: "rgba(255,255,255,0.5)",
  whiteA600: "rgba(255,255,255,0.6)",
  whiteA700: "rgba(255,255,255,0.7)",
  whiteA800: "rgba(255,255,255,0.8)",
  whiteA900: "rgba(255,255,255,0.9)",

  blackA50: "rgba(0,0,0,0.05)",
  blackA100: "rgba(0,0,0,0.1)",
  blackA200: "rgba(0,0,0,0.2)",
  blackA300: "rgba(0,0,0,0.3)",
  blackA400: "rgba(0,0,0,0.4)",
  blackA500: "rgba(0,0,0,0.5)",
  blackA600: "rgba(0,0,0,0.6)",
  blackA700: "rgba(0,0,0,0.7)",
  blackA800: "rgba(0,0,0,0.8)",
  blackA900: "rgba(0,0,0,0.9)",

  positive25: "#EFFBF0",
  positive50: "#DEF7E0",
  positive100: "#CEF3D1",
  positive200: "#ADEBB2",
  positive300: "#8CE393",
  positive400: "#6BDB75",
  positive500: "#4AD356",
  positive600: "#30C53C",
  positive700: "#208328",
  positive800: "#18631E",
  positive900: "#104214",

  negative25: "#FAF0F2",
  negative50: "#F5E0E4",
  negative100: "#F0D1D7",
  negative200: "#E6B2BC",
  negative300: "#DC93A2",
  negative400: "#D27487",
  negative500: "#C8566C",
  negative600: "#B93C55",
  negative700: "#9A3247",
  negative800: "#7B2839",
  negative900: "#5C1E2A",

  green50: "#eafbf7",
  green100: "#D9F4EE",
  green200: "#bff3e7",
  green300: "#94ebd7",
  green400: "#69e3c8",
  green500: "#31D8B3",
  green600: "#25c19e",
  green700: "#1c967b",
  green800: "#146b58",
  green900: "#0c4035",

  red50: "#ffe5ea",
  red100: "#ffb3bf",
  red200: "#ff8094",
  red300: "#FF617B",
  red400: "#ff1a3f",
  red500: "#e60026",
  red600: "#b3001d",
  red700: "#7d0014",
  red800: "#4d000d",
  red900: "#1a0004",

  blue50: "#e8f5fd",
  blue100: "#b9e2f9",
  blue200: "#8acef4",
  blue300: "#5bbaf0",
  blue400: "#159DEA",
  blue500: "#138dd3",
  blue600: "#0f6ea4",
  blue700: "#0b4e75",
  blue800: "#062f46",
  blue900: "#021017",

  gray50: "#f7f9fa",
  gray100: "#f0f2f4",
  gray200: "#e0e5ea",
  gray300: "#d1d9df",
  gray400: "#c1ccd5",
  gray500: "#b2bfca",
  gray600: "#8e99a2",
  gray700: "#6b7379",
  gray800: "#474c51",
  gray900: "#121314",

  orange50: "#FFE8D8",
  orange100: "#FFDEC6",
  orange200: "#FFCEAA",
  orange300: "#FFC090",
  orange400: "#FFA15B",
  orange500: "#FF8E3A",
  orange600: "#FF6C00",
  orange700: "#D35A00",
  orange800: "#9F4400",
  orange900: "#692D00z",
};
