import { useMutation } from "react-query";
import { api } from "../api";
import { PlatformsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const updatePlatformRequest = async (body: {
  platformId: string;
  name: string;
  file?: File;
}): Promise<void> => {
  const formData = new FormData();
  formData.append("name", body.name);
  formData.append("platformId", body.platformId);
  if (body.file) {
    formData.append("file", body.file);
  }
  await api.put("api/platform", formData);
};

export function useUpdatePlatform() {
  return useMutation(updatePlatformRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
