import styled from "styled-components";

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[7]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[7]};
`;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]};
  padding-top: ${({ theme }) => theme.spaces[6]};
  justify-content: end;
`;

export const SelectionListWrapper = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
`;
