import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
  Overlay,
} from "./modal.styles";
import { ModalHandle, ModalProps } from "./modal.types";
import { motion } from "framer-motion";
import { Headline, IconButton } from "../../atoms";

function Component(
  { children, size = "md", headline }: ModalProps,
  ref: Ref<ModalHandle>
): JSX.Element {
  const [visible, setVisible] = useState(false);

  function show() {
    setVisible(true);
  }

  function hide() {
    setVisible(false);
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      hide();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  if (!visible) return <></>;

  return (
    <Container $visible={visible}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Overlay onClick={hide} />
      </motion.div>
      <motion.div
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <Content $size={size}>
          <Header>
            <Headline {...headline} />
            <IconButton onClick={hide} iconName="close" />
          </Header>
          <Body>{children}</Body>
          <Footer />
        </Content>
      </motion.div>
    </Container>
  );
}

export const Modal = forwardRef<ModalHandle, ModalProps>(Component);
