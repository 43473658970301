import { useCallback, useEffect, useState } from "react";
import { useGetSession } from "../services/auth/use-get-session";

export function useCanAccess(permissions: string[]) {
  const { data: session } = useGetSession();
  const [canAccess, setCanAccess] = useState(false);

  const checkAccess = useCallback(() => {
    function canAccess() {
      for (let index = 0; index < permissions.length; index++) {
        const permission = permissions[index];
        const hasPermission = session?.permissions?.some(
          (item) => item === permission
        );
        if (!hasPermission) return false;
      }
      return true;
    }
    setCanAccess(canAccess());
  }, [permissions, session?.permissions]);

  useEffect(() => {
    checkAccess();
  }, [checkAccess]);

  return canAccess;
}
