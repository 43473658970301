import { ResponseType } from "axios";
import { setupApiClient } from "./api";

export type FetcherProps = {
  url: string;
  params?: object;
  context?: any;
  processor?: (data: any) => any;
  responseType?: ResponseType;
};

export const fetcher = async ({
  url,
  params,
  context,
  responseType,
  processor,
}: FetcherProps) => {
  const api = setupApiClient(context);
  const { data } = await api.get(url, { params, responseType });

  if (processor) {
    return processor(data);
  }
  return data;
};
