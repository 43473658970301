import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.negative900};
  color: ${({ theme }) => theme.colors.whiteA900};
  padding: ${({ theme }) => theme.spaces[4]} ${({ theme }) => theme.spaces[6]};
  border-radius: ${({ theme }) => theme.radii.md};
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.text_lg};
`;

export const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.text_md};
`;
