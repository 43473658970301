import styled from "styled-components";
import { TextInput } from "../../atoms";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[1]};
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spaces[6]};
`;

export const Search = styled(TextInput)`
  max-width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 5 0%;
  }
`;
