import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal, ModalHandle } from "../../../../components";
import {
  UpdateParticipantFormModalHandle,
  UpdateParticipantFormModalProps,
} from "./update-participant-form-modal.types";
import { ParticipantForm } from "../../components/participant-form/participant-form";
import {
  ParticipantFormDefaultValues,
  ParticipantFormSubmitData,
} from "../../components/participant-form/participant-form.types";

function Component(
  props: UpdateParticipantFormModalProps,
  ref: Ref<UpdateParticipantFormModalHandle>
): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [defaultValues, setDefaultValues] =
    useState<ParticipantFormDefaultValues>();

  function show(data: ParticipantFormDefaultValues) {
    setDefaultValues(data);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  async function onSubmit(data: ParticipantFormSubmitData) {
    return props.onSubmit({ ...data, participantId: defaultValues?.id! });
  }

  return (
    <Modal headline={{ title: "Editar participante" }} ref={modalRef}>
      <ParticipantForm
        {...props}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        fileRequired={false}
      />
    </Modal>
  );
}

export const UpdateParticipantFormModal = forwardRef<
  UpdateParticipantFormModalHandle,
  UpdateParticipantFormModalProps
>(Component);
