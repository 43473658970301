import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal, ModalHandle } from "../../../../components";
import {
  UpdateMusicFormModalHandle,
  UpdateMusicFormModalProps,
} from "./update-music-form-modal.types";
import { MusicForm } from "../../components/music-form/music-form";
import { MusicFormSubmitData } from "../../components/music-form/music-form.types";
import { Music } from "../../../../types/music";

function Component(
  props: UpdateMusicFormModalProps,
  ref: Ref<UpdateMusicFormModalHandle>
): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [defaultValues, setDefaultValues] = useState<Music>();

  function show(data: Music) {
    setDefaultValues(data);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  async function onSubmit(data: MusicFormSubmitData) {
    return props.onSubmit({ ...data, musicId: defaultValues?.id! });
  }

  return (
    <Modal headline={{ title: "Editar música" }} ref={modalRef}>
      <MusicForm {...props} defaultValues={defaultValues} onSubmit={onSubmit} />
    </Modal>
  );
}

export const UpdateMusicFormModal = forwardRef<
  UpdateMusicFormModalHandle,
  UpdateMusicFormModalProps
>(Component);
