import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { User } from "../../types/user";
import { UsersKeys as Keys } from "./state-keys";

const processor = (data: User[]): User[] => {
  return data;
};

export const useListUsers = (props?: UseFetchHooks<User[]>) => {
  const { context, params, config } = props || {};
  return useFetch<User[]>({
    key: Keys.list(),
    url: "api/user",
    params,
    context,
    config,
    processor,
  });
};
