import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spaces[4]};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.onBackgroundPrimary};
`;
