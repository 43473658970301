import { Badge, PrivateAvatar } from "../../atoms";
import { Container, Content, Description, Title } from "./list-item.styles";
import { ListItemProps } from "./list-item.types";

export function ListItem(props: ListItemProps) {
  return (
    <Container>
      {props.leftContent}
      {props.avatar && <PrivateAvatar {...props.avatar} />}
      <Content>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        {props?.badge && <Badge {...props.badge} />}
      </Content>
      {props.rightContent}
    </Container>
  );
}
