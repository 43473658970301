import { useRef } from "react";
import {
  Button,
  DefaultLayout,
  IconButton,
  Row,
  Alert,
  AlertHandle,
  Can,
  List,
} from "../../components";
import { useListUsers } from "../../services/users/use-list-users";
import { CreateUserFormModal } from "./modal/create-user-form-modal/create-user-form-modal";
import { CreateUserFormModalHandle } from "./modal/create-user-form-modal/create-user-form-modal.types";
import { UserFormSubmitData } from "./components/user-form/user-form.types";
import { useCreateUser } from "../../services/users/use-create-user";
import { useListRoles } from "../../services/auth/use-list-roles";
import { UpdateUserFormModal } from "./modal/update-user-form-modal/update-user-form-modal";
import {
  OnUpdateUserFormData,
  UpdateUserFormModalHandle,
} from "./modal/update-user-form-modal/update-user-form-modal.types";
import { User } from "../../types/user";
import { useUpdateUser } from "../../services/users/use-update-user";
import { useDeleteUser } from "../../services/users/use-delete-user";
import { useToast } from "../../hooks/use-toast";

export function UsersPage() {
  const { data: users } = useListUsers();
  const createUserFormModalRef = useRef<CreateUserFormModalHandle>(null);
  const updateUserFormModalRef = useRef<UpdateUserFormModalHandle>(null);
  const alertRef = useRef<AlertHandle>(null);
  const { errorHandler } = useToast();
  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { data: roles } = useListRoles();

  async function onClickDeleteUser(user: User) {
    alertRef?.current?.show({
      title: "Deseja mesmo excluir?",
      description: "Deseja mesmo excluir este usuário?",
      buttons: [
        {
          children: "Sim, excluir",
          onClick: () => onDeleteUser(user),
        },
        { children: "Não", variant: "ghost" },
      ],
    });
  }

  function onClickUpdateUser(user: User) {
    updateUserFormModalRef?.current?.show(user);
  }

  function onClickCreateUser() {
    createUserFormModalRef?.current?.show();
  }

  async function onCreateUser(data: UserFormSubmitData) {
    await createUser(data);
  }

  async function onUpdateUser(user: OnUpdateUserFormData) {
    await updateUser(user);
  }

  async function onDeleteUser(user: User) {
    try {
      await deleteUser({ userId: user.id });
    } catch (error) {
      errorHandler(error);
    }
  }

  return (
    <DefaultLayout
      headlineRightComponent={
        <Can permissions={["user.create"]}>
          <Button
            leftIconName="add"
            onClick={onClickCreateUser}
            variant="outline"
          >
            Adicionar usuário
          </Button>
        </Can>
      }
      headline={{ title: "Usuários" }}
    >
      {users?.length && (
        <List
          data={users.map((user) => ({
            title: user.name,
            description: user.email,
            badge: { text: user.role },
            rightContent: (
              <Row>
                <Can permissions={["user.update"]}>
                  <IconButton
                    iconName="edit"
                    onClick={() => onClickUpdateUser(user)}
                  />
                </Can>
                <Can permissions={["user.delete"]}>
                  <IconButton
                    iconName="delete"
                    onClick={() => onClickDeleteUser(user)}
                  />
                </Can>
              </Row>
            ),
          }))}
        />
      )}

      <CreateUserFormModal
        roles={roles || []}
        ref={createUserFormModalRef}
        onRequestClose={() => createUserFormModalRef?.current?.hide()}
        onSubmit={onCreateUser}
      />
      <UpdateUserFormModal
        roles={roles || []}
        ref={updateUserFormModalRef}
        onRequestClose={() => updateUserFormModalRef?.current?.hide()}
        onSubmit={onUpdateUser}
      />
      <Alert ref={alertRef} />
    </DefaultLayout>
  );
}
