import styled, { DefaultTheme, css } from "styled-components";
import { Icon } from "../icon/icon";
import { IconButtonVariant } from "./icon-button.types";

const variantStyles = (theme: DefaultTheme, variant: IconButtonVariant) =>
  ({
    solid: css`
      color: ${theme.colors.onPrimaryPrimary};
      background-color: ${theme.colors.primary};
      &:hover {
        opacity: 0.8;
      }
    `,
    ghost: css`
      color: ${theme.colors.whiteA900};
      background-color: ${theme.colors.background};

      &:hover {
        opacity: 0.8;
        background-color: ${theme.colors.background};
      }
    `,
  }[variant]);

export const Container = styled.button<{ $variant: IconButtonVariant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.sizes[9]};
  width: ${({ theme }) => theme.sizes[9]};
  border-radius: ${({ theme }) => theme.radii.sm};
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-color: transparent;

  &:hover {
    transform: 0.2s ease all;
    opacity: 0.7;
  }

  ${({ theme, $variant }) => variantStyles(theme, $variant)}
`;

export const Content = styled(Icon).attrs(({ theme }) => ({
  // color: theme.colors.onBackgroundPrimary,
  size: 18,
}))``;
