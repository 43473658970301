import styled from 'styled-components'

export const Container = styled.div<{ $numberOfColumns: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spaces[2]};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: ${({ $numberOfColumns }) =>
      Array.from(Array($numberOfColumns).keys()).map(() => '1fr ')};
  }
`
