import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal, ModalHandle } from "../../../../components";
import { UserForm } from "../../components/user-form/user-form";
import {
  UpdateUserFormModalHandle,
  UpdateUserFormModalProps,
} from "./update-user-form-modal.types";
import { User } from "../../../../types/user";
import { UserFormSubmitData } from "../../components/user-form/user-form.types";

function Component(
  props: UpdateUserFormModalProps,
  ref: Ref<UpdateUserFormModalHandle>
): JSX.Element {
  const modalRef = useRef<ModalHandle>(null);
  const [defaultValues, setDefaultValues] = useState<User>();

  function show(data: User) {
    setDefaultValues(data);
    modalRef?.current?.show();
  }

  function hide() {
    modalRef?.current?.hide();
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  async function onSubmit(data: UserFormSubmitData) {
    return props.onSubmit({ ...data, id: defaultValues?.id! });
  }

  return (
    <Modal headline={{ title: "Editar usuário" }} ref={modalRef}>
      <UserForm {...props} defaultValues={defaultValues} onSubmit={onSubmit} />
    </Modal>
  );
}

export const UpdateUserFormModal = forwardRef<
  UpdateUserFormModalHandle,
  UpdateUserFormModalProps
>(Component);
