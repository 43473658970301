import { useMutation } from "react-query";
import { api } from "../api";
import { PlatformsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const createPlatformRequest = async (body: {
  name: string;
  file: File;
}): Promise<void> => {
  const formData = new FormData();
  formData.append("name", body.name);
  formData.append("file", body.file);
  await api.post("api/platform", formData);
};

export function useCreatePlatform() {
  return useMutation(createPlatformRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
