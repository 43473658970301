import { useFetch, UseFetchHooks } from "../../hooks/use-fetch";
import { Participant, ParticipantStatus } from "../../types/participant";
import { ParticipantsKeys as Keys } from "./state-keys";

type StatusAdapterProps = {
  [key in ParticipantStatus]: { title: string; color: string };
};

export const statusAdapter: StatusAdapterProps = {
  activated: { title: "Ativado", color: "#FDD92D" },
  eliminated: { title: "Eliminado", color: "#DC93A2" },
};

type ParticipantList = Participant & {
  statusText: string;
  statusColor: string;
};

const processor = (data: Participant[]): ParticipantList[] => {
  return data.map((item) => ({
    ...item,
    statusText: statusAdapter[item.status].title,
    statusColor: statusAdapter[item.status].color,
  }));
};

export const useListParticipants = (
  props?: UseFetchHooks<ParticipantList[]>
) => {
  const { context, params, config } = props || {};
  return useFetch<ParticipantList[]>({
    key: Keys.list(),
    url: "api/participant",
    params,
    context,
    config,
    processor,
  });
};
