import { useGetSession } from "../../../services/auth/use-get-session";
import { IconButton } from "../../atoms";
import { Container, WrapperRight } from "./header.styles";
import { HeaderProps } from "./header.types";

export function Header({ onClickMenu }: HeaderProps) {
  const { data: session } = useGetSession();
  return (
    <Container>
      <IconButton onClick={onClickMenu} iconName="menu" />
      <WrapperRight>{session?.email}</WrapperRight>
    </Container>
  );
}
