import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { UsersPage } from "../pages/users/users";
import { SignIn } from "../pages/auth/sign-in/sign-in";
import { Redirect } from "../pages/auth/redirect/redirect";
import { PrivateScreen } from "../components/templates/private-screen/private-screen";
import { DashboardPage } from "../pages/dashboard/dashboard";
import { useEffect } from "react";
import { MusicsPage } from "../pages/musics/musics";
import { ParticipantsPage } from "../pages/participants/participants";
import { PlatformsPage } from "../pages/platforms/platforms";
import { CalculationPeriodsPage } from "../pages/calculation-periods/calculation-periods";
import { CalculationPeriodDetailPage } from "../pages/calculation-periods/calculation-period-detail/calculation-period-detail";
import { DashboardDetailPage } from "../pages/dashboard/dashboard-detail/dashboard-detail";
import { LogsPage } from "../pages/logs/logs";
import { LogOutPage } from "../pages/auth/log-out/log-out";
import { AnalyticsPage } from "../pages/analytics/analytics";

export function IndexPage(): React.JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard");
  }, [navigate]);

  return <></>;
}

export const applicationRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateScreen>
        <IndexPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateScreen>
        <DashboardPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/dashboard/detail",
    element: (
      <PrivateScreen>
        <DashboardDetailPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/analytics",
    element: (
      <PrivateScreen>
        <AnalyticsPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/users",
    element: (
      <PrivateScreen>
        <UsersPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/musics",
    element: (
      <PrivateScreen>
        <MusicsPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/participants",
    element: (
      <PrivateScreen>
        <ParticipantsPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/platforms",
    element: (
      <PrivateScreen>
        <PlatformsPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/calculation-periods",
    element: (
      <PrivateScreen>
        <CalculationPeriodsPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/calculation-periods/detail",
    element: (
      <PrivateScreen>
        <CalculationPeriodDetailPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/logs",
    element: (
      <PrivateScreen>
        <LogsPage />
      </PrivateScreen>
    ),
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/redirect",
    element: <Redirect />,
  },
  {
    path: "/log-out",
    element: <LogOutPage />,
  },
]);

export function Routes(): JSX.Element {
  return <RouterProvider router={applicationRouter} />;
}
