import { useCallback, useEffect, useState } from "react";
import { PrivateScreenProps } from "./private-screen.types";
import { SessionStorage } from "../../../storage/session-storage";
import { fetchLoginGlobo } from "../../../services/auth/fetch-login-globo";
import { fetchMe } from "../../../services/auth/fetch-me";
import { Container } from "./private-screen.styles";
import { Spinner } from "../../atoms";
import { useToast } from "../../../hooks/use-toast";

export function PrivateScreen({ children }: PrivateScreenProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useToast();

  const handleLoginGlobo = useCallback(async () => {
    try {
      const { endpoint, key } = await fetchLoginGlobo();
      document.cookie = `SESS_VERIFIER=${key};max-age=604800;`;
      window.location.href = endpoint;
    } catch (error) {
      errorHandler(error);
    }
  }, [errorHandler]);

  const checkSession = useCallback(async () => {
    try {
      await fetchMe();
    } catch (error) {
      errorHandler(error);
      handleLoginGlobo();
    }
  }, [handleLoginGlobo, errorHandler]);

  const fetchSession = useCallback(async () => {
    const sessionStorage = new SessionStorage();
    const tokenData = sessionStorage.get();
    if (!tokenData) {
      return handleLoginGlobo();
    }
    await checkSession();
    setIsLoading(false);
  }, [checkSession, handleLoginGlobo]);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  return isLoading ? (
    <Container>
      <Spinner size="86px" />
    </Container>
  ) : (
    <>{children}</>
  );
}
