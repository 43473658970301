import { useMutation } from "react-query";
import { api } from "../api";
import { PlatformsKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const deletePlatformRequest = async (params: {
  platformId: string;
}): Promise<void> => {
  await api.delete("api/platform", { params });
};

export function useDeletePlatform() {
  return useMutation(deletePlatformRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
