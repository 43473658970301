import { type FontSizeProps } from '../../../types/theme'

export const fontSizes: FontSizeProps = {
  text_xs: '0.7rem',
  text_sm: '0.87rem',
  text_md: '0.9rem',
  text_lg: '1rem',
  text_xl: '1.2rem',
  display_xs: '1.6rem',
  display_sm: '1.8rem',
  display_md: '2rem',
  display_lg: '2.4rem',
  display_xl: '3rem',
  display_2xl: '4rem',
}
