import styled from "styled-components";

export const Container = styled.strong<{ $bgColor?: string }>`
  background-color: ${({ theme, $bgColor }) =>
    $bgColor || theme.colors.primary};
  color: ${({ theme }) => theme.colors.onPrimaryPrimary};
  align-self: flex-start;
  padding: 0px ${({ theme }) => theme.spaces[4]};
  border-radius: ${({ theme }) => theme.radii.md};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.text_xs};
`;
