import { Wrapper, Option, Label, Container } from "./select.styles";
import { SelectProps } from "./select.types";

export function Select({ label, options, ...props }: SelectProps) {
  return (
    <Container>
      <Label htmlFor={props.name}>{label}</Label>
      <Wrapper id={props.name} {...props}>
        {options.map((option) => (
          <Option value={option.value} key={option.value}>
            {option.title}
          </Option>
        ))}
      </Wrapper>
    </Container>
  );
}
