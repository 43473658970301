import { FiltersLogsFormProps } from "./filters-logs-form.types";
import { Button, TextInput } from "../../../../components";
import {
  Footer,
  Content,
  Container,
  DatesRow,
  DateInput,
} from "./filters-logs-form.styles";
import { useToast } from "../../../../hooks/use-toast";
import { useState } from "react";

export function FiltersLogsForm(props: FiltersLogsFormProps) {
  const { errorHandler } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    try {
      evt.preventDefault();
      setIsLoading(true);
      const formData = new FormData(evt.target as any);
      const data = Object.fromEntries(formData) as { [key: string]: any };
      await props.onSubmit(data);
      props.onRequestClose();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Content onSubmit={onSubmit}>
        <TextInput
          label="Pesquisa geral"
          placeholder="Digite aqui sua pesquisa"
          name="search"
          defaultValue={props.defaultValues?.search}
        />
        <DatesRow>
          <DateInput
            label="Começa em"
            name="starts_at"
            type="datetime-local"
            defaultValue={props.defaultValues?.starts_at}
          />
          <DateInput
            label="Termina em"
            name="ends_at"
            type="datetime-local"
            defaultValue={props.defaultValues?.ends_at}
          />
        </DatesRow>
        <TextInput
          label="Usuário"
          placeholder="Digite aqui o nome do usuário"
          name="username"
          defaultValue={props.defaultValues?.username}
        />
        <Footer>
          <Button type="button" variant="ghost" onClick={props.onRequestClose}>
            Cancelar
          </Button>
          <Button isLoading={isLoading} type="submit">
            Salvar
          </Button>
        </Footer>
      </Content>
    </Container>
  );
}
