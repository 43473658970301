import { useMutation } from "react-query";
import { api } from "../api";
import { UsersKeys as Keys } from "./state-keys";
import { queryClient } from "../query-client";

export const createUserRequest = async (body: {
  name: string;
  email: string;
  role: string;
}): Promise<void> => {
  await api.post("api/user", body);
};

export function useCreateUser() {
  return useMutation(createUserRequest, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(Keys.list());
    },
  });
}
