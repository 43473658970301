import { useState } from "react";
import { Button, TextInput } from "../../../../../components";
import { Container, Footer, Item, Wrapper } from "./musics-views-form.styles";
import { MusicsViewsFormProps } from "./musics-views-form.types";
import { useToast } from "../../../../../hooks/use-toast";

export function MusicsViewsForm(props: MusicsViewsFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useToast();

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    try {
      evt.preventDefault();
      setIsLoading(true);
      const formData = new FormData(evt.target as any);
      const data = Object.fromEntries(formData) as { [key: string]: string };
      const formattedData: {
        id: string;
        viewsCount: number;
      }[] = [];
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          formattedData.push({
            id: key,
            viewsCount: Number(data[key]),
          });
        }
      });
      await props.onSubmit(formattedData);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Wrapper onSubmit={onSubmit}>
      <Container>
        {props.musics.map((music) => (
          <Item key={music.id}>
            <TextInput
              placeholder="Número de views"
              type="number"
              name={music.id}
              label={music.name}
              helpText={music.participant?.name}
            />
          </Item>
        ))}
      </Container>
      <Footer>
        <Button isLoading={isLoading} type="submit">
          Salvar
        </Button>
      </Footer>
    </Wrapper>
  );
}
