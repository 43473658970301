import styled, { DefaultTheme, css } from "styled-components";
import { ButtonVariant } from "./button.types";

const variantStyles = (
  theme: DefaultTheme,
  variant: ButtonVariant,
  danger?: boolean
) =>
  ({
    solid: css`
      color: ${theme.colors.onPrimaryPrimary};
      background-color: ${danger
        ? theme.colors.negative400
        : theme.colors.primary};
      &:hover {
        opacity: 0.8;
      }
    `,
    ghost: css`
      color: ${theme.colors.primary};
      background-color: ${theme.colors.surface};

      &:hover {
        opacity: 0.8;
        background-color: ${theme.colors.background};
      }
    `,
    outline: css`
      color: ${theme.colors.primary};
      background: transparent;
      border: 1px solid ${theme.colors.primary};

      &:hover {
        opacity: 0.8;
        background-color: ${theme.colors.primary};
        color: ${theme.colors.onPrimaryPrimary};
      }
    `,
  }[variant]);

export const Container = styled.button<{
  $variant: ButtonVariant;
  $danger?: boolean;
}>`
  display: flex;
  padding: ${({ theme }) => theme.spaces[3]} ${({ theme }) => theme.spaces[8]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.radii.sm};
  border-color: transparent;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.text_sm};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  transition: 0.2s ease all;
  justify-self: center;
  gap: ${({ theme }) => theme.spaces[3]};
  max-width: 320px;

  ${({ theme, $variant, $danger }) => variantStyles(theme, $variant, $danger)}
`;
