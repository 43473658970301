import { MusicFormProps } from "./music-form.types";
import { Button, Select, TextInput } from "../../../../components";
import {
  Footer,
  Content,
  Container,
  RowAutomaticPlatforms,
} from "./music-form.styles";
import { useToast } from "../../../../hooks/use-toast";
import { useState } from "react";

const PLATFORM_PREFIX = "platform-";

export function MusicForm(props: MusicFormProps) {
  const { errorHandler } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    try {
      evt.preventDefault();
      setIsLoading(true);
      const formData = new FormData(evt.target as any);
      const data = Object.fromEntries(formData) as any;
      const integratedPlatforms = Object.keys(data)
        .filter((key) => key.includes(PLATFORM_PREFIX) && data[key]?.length)
        .map((key) => {
          const id = key.split(PLATFORM_PREFIX)[1];
          return { platformId: id, platformMusicId: data[key] };
        });
      await props.onSubmit({
        name: data.name,
        participantId: data.participantId,
        integratedPlatforms,
      });
      props.onRequestClose();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Content onSubmit={onSubmit}>
        <TextInput
          label="Nome"
          placeholder="Digite aqui"
          name="name"
          required
          defaultValue={props.defaultValues?.name}
        />
        <Select
          options={props.participants.map((item) => ({
            title: item.name,
            value: item.id,
          }))}
          label="Participante"
          name="participantId"
          defaultValue={props.defaultValues?.participant?.id}
          required
        />
        <RowAutomaticPlatforms>
          {props.automaticPlatforms.map((platform) => (
            <TextInput
              key={platform.id}
              label={`${platform.name} ID`}
              placeholder="Digite o ID"
              name={`${PLATFORM_PREFIX}${platform.id}`}
              defaultValue={
                props.defaultValues?.integratedPlatforms?.find(
                  (item) => item.platformId === platform.id
                )?.platformMusicId
              }
            />
          ))}
        </RowAutomaticPlatforms>

        <Footer>
          <Button type="button" variant="ghost" onClick={props.onRequestClose}>
            Cancelar
          </Button>
          <Button isLoading={isLoading} type="submit">
            Salvar
          </Button>
        </Footer>
      </Content>
    </Container>
  );
}
