import { useCallback } from "react";
import { ToastOptions, toast } from "react-toastify";

export function useToast() {
  function showToast(msg: string, options?: ToastOptions) {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      theme: "dark",
      ...options,
    });
  }

  function showMessage(msg: string) {
    showToast(msg, { type: "success" });
  }

  const errorHandler = useCallback((err: any) => {
    if (err?.response?.data?.errors) {
      const errors = err?.response?.data?.errors as {
        message: string;
        field?: string;
        description?: string;
      }[];
      errors?.forEach((error) => {
        const msg = `${error?.message} - ${error?.description || ""} ${
          error?.field || ""
        }`;
        showToast(msg);
      });
    } else {
      showToast(err.request);
    }
  }, []);

  return { errorHandler, showMessage };
}
